import React from "react";
import { styled } from "@mui/system"
import Button from "@mui/material/Button";
import { withAuthenticator } from "@aws-amplify/ui-react";


const RootStyled = styled('div')(({ theme }) => ({
    '& > *': {
        margin: theme.spacing(1),
    },
}));

const InputStyled = styled('input')({
    display: 'none',
});

const ImageContainerStyled = styled('div')(({ theme }) => ({
    position: 'relative',
    margin: "0 auto",
    maxWidth: "100%",
    height: '350px',
    "& img": {
        width: "100%",
        maxHeight: "350px", 
        borderRadius: "10px",
        transition: '0.3s',
        objectFit: "cover",
    },
    "&:hover img": {
        opacity: 0.5,
    },
    "&:hover div": {
        opacity: 1,
    },
    "& div": {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 0,
        transition: '0.3s',
        color: 'white',
        fontSize: '20px',
        textAlign: 'center',
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%', // Match the width of the ImageContainer
    height: '350px', // Match the height of the ImageContainer
    borderRadius: '10px', // Match the borderRadius of the ImageContainer
    backgroundColor: 'gray', // Set the background color to gray
    color: 'white', // Set the text color to white
    '&:hover': {
        backgroundColor: 'darkgray', // Change the background color when hovered
    },
}));

function ImageUploadComponent({ setImageFile, setImageUrl, imageFile, imageUrl }) {
    async function handleImageChange(event) {
        const file = event.target.files[0];
        if (!file) return;
        setImageFile(file);
        const url = URL.createObjectURL(file);
        setImageUrl(url);
    }

    return (
        <RootStyled>
            <InputStyled
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={handleImageChange}
            />
            <label htmlFor="contained-button-file">
                {imageFile || imageUrl ? (
                    <ImageContainerStyled>
                        <img src={imageUrl ?  imageUrl : URL.createObjectURL(imageFile)} alt="Selected" />
                        <div>Select different image</div>
                    </ImageContainerStyled>
                ) : (
                    <StyledButton variant="contained" color="primary" component="span">
                        Upload Image
                    </StyledButton>
                )}
            </label>
        </RootStyled>
    );
}

export default withAuthenticator(ImageUploadComponent);