import React from "react";
import { Container, Typography, Chip, IconButton, Avatar, Tooltip, Box, Grid, Divider } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { styled } from "@mui/system";
import Section from "./Section";
import { FacebookShareButton, TwitterShareButton, PinterestShareButton } from "react-share";
import { Facebook, X, Pinterest } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { getFamilyOriginsDisplayValues } from "../util/constants";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    float: "right",
}));

function FamilyViewSection(props) {
    function getEnumFromDisplay(displayValue) {
        for (const enumValue in getFamilyOriginsDisplayValues) {
            if (enumValue === displayValue) {
                return getFamilyOriginsDisplayValues[enumValue];
            }
        }
        return null; // Return null if displayValue not found
    }


    return (
        <Section
            size={props.size}
        >
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            {props.family.name}
                        </Typography>
                        {props.family?.origins?.map((origin) => {
                            return (
                                <Chip key={getEnumFromDisplay(origin)} label={getEnumFromDisplay(origin)} />
                            );
                        })}
                    </Grid>
                    <Grid item xs={12} style={{paddingTop:'8px'}}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 100 }}>
                            <IconButton size="small">
                                <FacebookShareButton url={window.location.href} quote={props.family.name}>
                                    <Facebook />
                                </FacebookShareButton>
                            </IconButton>
                            <IconButton size="small">
                                <TwitterShareButton url={window.location.href} title={props.family.name}>
                                    <X />
                                </TwitterShareButton>
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} style={{paddingTop:'8px'}}>
                        <Typography variant="body1" >
                            {props.family.about}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                            {props.members && props.members
                                .filter(member => member.username === props.family.owner)
                                .map(member => (
                                    <Tooltip title={member.username} key={member.username}>
                                        <Avatar
                                            component={Link}
                                            to={"/user?id=" + member.id}
                                            key={member.username}
                                            src={member.imageUrl}
                                            alt={member.username}
                                            sx={{
                                                width: 60,
                                                height: 60,
                                                border: '2px solid white',
                                            }}
                                        />
                                    </Tooltip>
                                ))
                            }
                            {props.members && props.members
                                .filter(member => member.username !== props.family.owner)
                                .map(member => (
                                    <Tooltip title={member.username} key={member.username}>
                                        <Avatar
                                            component={Link}
                                            to={"/user?id=" + member.id}
                                            key={member.username}
                                            src={member.imageUrl}
                                            alt={member.username}
                                            sx={{
                                                width: 40,
                                                height: 40,
                                                marginLeft: 1,
                                            }}
                                        />
                                    </Tooltip>
                                ))
                            }
                        </div>
                    </Grid>
                    {props.editable &&
                        <Grid item xs={12}>

                            <StyledIconButton component={Link} to={"/familyEdit?id=" + props.family.id}>
                                <Edit />
                            </StyledIconButton>

                        </Grid>
                    }                    
                </Grid>
            </Container>
            <Divider variant="inset" />
        </Section>
    );
}

export default FamilyViewSection;
