import React, { useEffect, useState } from "react";
import { getRecipe, getLikesByUser } from "../util/api";
import RecipeList from "../components/RecipeList";
import Typography from "@mui/material/Typography";

function RecipeLikesUser(props) {
    const [recipes, setRecipes] = useState([]);
    const [error, setError] = useState(false);
    useEffect(() => {
        async function fetchLikes() {
            try
            {
                const user = props.user;
                if(!user){
                    setError(true); 
                    console.log("No current user")
                    return;
                }
                const likes = await getLikesByUser(user.id);
                const recipes = await Promise.all(likes.map(like => getRecipe(like.recipeID)));
                //if a recipe is null, it means it was deleted, so we remove it from the likes
                for (let i = 0; i < recipes.length; i++) {
                    if (recipes[i] === null) {
                        likes.splice(i, 1);
                        recipes.splice(i, 1);
                    }
                }
                setRecipes(recipes);
            }
            catch (error)
            {
                console.error(error);
                setError(true);
            }
        }

        fetchLikes();
        
    }, []);

    if (error) {
        return <Typography variant="h4">Error fetching likes</Typography>;
    }

    return (
        <RecipeList title={props.title} size="auto" recipes={recipes} />
    );
}

export default RecipeLikesUser;
