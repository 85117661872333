import React, { useState } from "react";
import Button from "@mui/material/Button";
import FilterBar from "./FilterBar";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from "@mui/system";

const DivStyled = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 10,
});

const AccordionStyled = styled(Accordion)({
  width: '50%',
  borderRadius: 10,
  boxShadow: '0px 0px 0px 0px',
  backgroundColor: 'transparent',
  alignContent: 'center',
});

const AccordionSummaryStyled = styled(AccordionSummary)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

const ButtonStyled = styled(Button)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'white',
  color: 'black',
});


export default function FilterAccordion(props) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <DivStyled>
      <AccordionStyled expanded={expanded === 'panel1'} elevation={0} onChange={handleChange('panel1')}>
        <AccordionSummaryStyled
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div style={{flexGrow: 1}}></div>
          <ButtonStyled >
            Filter Results<FilterAltIcon />
          </ButtonStyled>
          <div style={{flexGrow: 1}}></div>
        </AccordionSummaryStyled>
        <AccordionDetails>
          <FilterBar {...props} />
        </AccordionDetails>
      </AccordionStyled>
    </DivStyled>
  );
}