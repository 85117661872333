import React from "react";
import { Container, Grid, Box, Typography, Link as LinkMui, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { styled } from "@mui/system";
import Section from "./Section";
import { Link } from "react-router-dom";
import RRWOSlogan from "./../images/Recipe Roots Without Slogan.png"

const StyledSection = styled(Section)(({ theme }) => ({
  marginTop: "auto",
}));

const StyledBrand = styled('img')({
  display: "block",
  height: 32,
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: 2,
  paddingBottom: 2,
  paddingLeft: 12,
  paddingRight: 12,
}));

const StyledListItemTextHeader = styled(Typography)({
  fontWeight: "bold",
});

const StyledSocialIcon = styled(ListItemIcon)({
  minWidth: 30,
});

const StyledLegal = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  fontSize: "0.875rem",
  opacity: 0.6,
  "& a": {
    color: "inherit",
    marginLeft: "0.8rem",
  },
}));

function Footer(props) {

  // Use inverted logo if specified
  // and we are in dark mode
  const logo = RRWOSlogan;

  return (
    <StyledSection
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Grid container={true} justifyContent="space-between" spacing={4}>
          <Grid item={true} xs={12} md={9}>
            {/* Empty Grid item to take up the first half of the screen */}
          </Grid>
          <Grid item={true} xs={12} md={3}>
            <Link to="/">
              <StyledBrand src={logo} alt="Logo" />
            </Link>
            <StyledLegal>
              <LinkMui component={Link} to="https://ko-fi.com/reciperoots" target="_blank" rel="noopener noreferrer">
                Support us!
              </LinkMui>
            </StyledLegal>
          </Grid>
        </Grid>
      </Container>
    </StyledSection>
  );
}

export default Footer;
