import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import KoFiImage from '../images/kofi.png';
import KoFiLinkSetup from './KoFiLinkSetup';

function KoFiLink(props) {
  const [open, setOpen] = useState(false);
  const [kofiLink, setKofiLink] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    //run validation on kofiLink so we know it is not a bad link
    let currentLink = props.user?.kofiLink;
    if (currentLink) {
      if (currentLink.includes("https://ko-fi.com/")) {
        //Grab the last part of the link, and we will append that in the render
        let splitLink = currentLink.split("/");
        setKofiLink(splitLink[splitLink.length - 1]);
      }
    }
  }, [props.user?.kofiLink]);

  // If the profile is public and the user does not have a kofi link, do not render anything
  if (props.public && (!props.user?.kofiLink || props.user?.kofiLink === "")) {
    return null;
  }

  return (
    <div>
      {props.public ?
        <>
          {props.user?.kofiLink &&
            <>
              <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
                Tip {props.user?.username} <img src={KoFiImage} alt="Ko-Fi" style={{ width: '30px', height: '30px' }} />
              </Button>
              <Dialog open={open} onClose={handleClose}>
                <iframe
                  id='kofiframe'
                  src={'https://ko-fi.com/'+kofiLink+'/?hidefeed=true&widget=true&embed=true&preview=true'}
                  style={{ border: 'none', width: '100%', padding: '4px', background: '#f9f9f9' }}
                  height='712'
                  title='reciperoots'
                />
              </Dialog>
            </>
          }
        </>
        :
        <KoFiLinkSetup user={props.user} />
      }
    </div>
  );
}

export default KoFiLink;