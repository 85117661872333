import React from "react";
import Meta from "../components/Meta";
import RecipeEditForm from "../components/RecipeEditForm";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useLocation } from 'react-router-dom';
function RecipeEditPage(props) {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const recipeId = queryParams.get('recipeId');
  
  return (
    <>
    <Meta title="Recipe Roots - Edit"/>
      <RecipeEditForm recipeId={recipeId} size="appBar"/>
    </>
    );
}

export default withAuthenticator(RecipeEditPage);
