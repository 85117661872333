import { addLike, deleteLike } from "./api";
export const toggleLikeOnRecipe = async (liked, likeId, recipeId, user) => {
    try {
      if (!user) 
      {
        //direct them to login
        window.location.href = "/dashboard/0";
        return;
      }
      if (liked) {
        //delete like
        await deleteLike(likeId);
        return 0; // 0 means we deleted the like
      }
      const response = await addLike(recipeId, user.userId);
      return response.id;// return the id of the like

    } catch (error) {
      console.log('error setting like on recipe: ', error);
      throw error;
    }
}

// export const getRecipeTagsDisplay = (tags) => {
//   return tags.map(tag => getRecipeTagsDisplayValue[tag]);
// }

// export const getFamilyOriginsDisplay = (origins) => {
//   return origins.map(origin => getFamilyOriginsDisplayValues[origin]);
// }

// //convert the display values back to enum value
// export const getRecipeTagsEnum = (tags) => {
//   return tags.map(tag => recipeTags.find(tagEnum => getRecipeTagsDisplayValue[tagEnum] === tag));
// }

// export const getFamilyOriginsEnum = (origins) => {
//   return origins.map(origin => familyOriginsTags.find(originEnum => getFamilyOriginsDisplayValues[originEnum] === origin));
// }


