import React from "react";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { getFamilyOriginsDisplayValues, getRecipeTagsDisplayValue } from "../util/constants";
import { styled } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";

const MultipleSelects = styled(Select)({
  width: 200,
});

const GridContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '20px',
  paddingBottom: '20px',
  borderBottom: '1px solid #e0e0e0',
});

export default function FilterBar(props) {
  const origins = Object.entries(getFamilyOriginsDisplayValues);
  const tags = Object.entries(getRecipeTagsDisplayValue);

  //sort these in alphabetical order by display name
  origins.sort((a, b) => a[1].localeCompare(b[1]));
  tags.sort((a, b) => a[1].localeCompare(b[1]));

  return (
    <Container>
      <GridContainer container>
        <Grid item>
          <InputLabel>Family Origins</InputLabel>
          <MultipleSelects
            label="Family Origins"
            multiple
            value={props.mealOrigins}
            renderValue={(selected) => selected.map(value => origins.find(([enumValue]) => enumValue === value)[1]).join(', ')}
          >
            {origins.map(([enumValue, displayName]) => (
              <MenuItem key={enumValue} value={enumValue}>
                <ListItemText primary={displayName} />
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={props.mealOrigins.includes(enumValue)}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      props.setMealOrigins(prevOrigins =>
                        checked
                          ? [...prevOrigins, enumValue]
                          : prevOrigins.filter(item => item !== enumValue)
                      );
                    }}
                  />
                </ListItemSecondaryAction>
              </MenuItem>
            ))}
          </MultipleSelects>
        </Grid>
        <Grid item>
          <InputLabel>Meal Tags</InputLabel>
          <MultipleSelects
            label="Meal Tags"
            multiple
            value={props.mealTags}
            renderValue={(selected) => selected.map(value => tags.find(([enumValue]) => enumValue === value)[1]).join(', ')}
          >
            {tags.map(([enumValue, displayName]) => (
              <MenuItem key={enumValue} value={enumValue}>
                <ListItemText primary={displayName} />
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={props.mealTags.includes(enumValue)}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      props.setMealTags(prevTags =>
                        checked
                          ? [...prevTags, enumValue]
                          : prevTags.filter(item => item !== enumValue)
                      );
                    }}
                  />
                </ListItemSecondaryAction>
              </MenuItem>
            ))}
          </MultipleSelects>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            <Box mt="auto">
              <Button variant="outlined" onClick={() => { props.setMealOrigins([]); props.setMealTags([]); }}>
                Clear Filters
              </Button>
            </Box>
          </Box>
        </Grid>
      </GridContainer>
    </Container>
  )
}