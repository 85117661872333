import React from "react";
import Meta from "../components/Meta";
import RecipeView from "../components/RecipeView";
function recipeViewPage(props) {

  return (
    <>
    <Meta title="Recipe"/>
      <RecipeView size="appBar"/>
    </>
    );
}

export default recipeViewPage;
