import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { Grid, MenuItem, TextField, Select, Typography, InputLabel, Chip, FormHelperText, Button, OutlinedInput } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Checkbox from "@mui/material/Checkbox";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { generateClient } from "aws-amplify/api";
import { createFamily, createUserFamily } from "../graphql/mutations";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { getCurrentUser } from 'aws-amplify/auth';
import { getFamilyOriginsDisplayValues } from "../util/constants";
import { styled } from "@mui/system";

const StyledGridItem = styled(Grid)(({ theme }) => ({
    textAlign: "center",
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    width: "45%",
    padding: theme.spacing(1),
    borderRadius: 40,
}));

const TextArea = styled(TextField)({
    width: "75%",
});

const TextInput = styled(TextField)({
    width: "40%",
    minWidth: "225px",
});

const MultipleSelects = styled(Select)({
    width: "50%",
});



function FamilyCreateForm(props) {
    const client = generateClient();
    const [user, setUser] = useState({});
    const [familyOrigin, setFamilyOrigin] = useState([]);
    const [FamilyName, setFamilyName] = useState("");
    const [About, setAbout] = useState("");
    const [FamilyNameError, setFamilyNameError] = useState(false);

    const origins = Object.entries(getFamilyOriginsDisplayValues);
    //convert the origins to alphabetized list
    origins.sort((a, b) => a[1].localeCompare(b[1]));

    useEffect(() => {
        const fetchData = async () => {
            const user = await getCurrentUser();
            setUser(user);
        }

        fetchData();
    }, []);

    //create validation function
    function validate() {
        var validatitionCorrect = true;
        if (FamilyName === "") {
            setFamilyNameError(true);
            validatitionCorrect = false;
        }
        return validatitionCorrect;
    }


    async function createRecipeOnSubmit() {
        if (!validate()) {
            return;
        };
        if (!window.confirm("Creating this family will allow you to add your recipes on the Create/Edit Recipe page.")) {
            return;
        }
        const recipe = {
            name: FamilyName,
            about: About,
            origins: familyOrigin
        };
        try {
            const familyResponse = await client.graphql({
                query: createFamily.replaceAll("__typename", ""),
                variables: {
                    input: {
                        ...recipe,
                    }
                }
            });
            console.log(familyResponse);
            //check for errors
            if (familyResponse.errors) {
                console.log(familyResponse.errors);
                return;
            }

            //create user to family relationship
            const userFamily = {
                userID: user.userId,
                familyID: familyResponse.data.createFamily.id,
                familyOwner: user.userId
            };
            const userFamilyResponse = await client.graphql({
                query: createUserFamily.replaceAll("__typename", ""),
                variables: {
                    input: {
                        ...userFamily,
                    }
                }
            });
            if (userFamilyResponse.errors) {
                console.log(userFamilyResponse.errors);
                return;
            }
            window.location.href = "/familyEdit?id=" + familyResponse.data.createFamily.id;
        }
        catch (error) {
            console.log(error);
        }

    }


    return (
        <Section size={props.size}>
            <Container>
                <SectionHeader
                    title="Create your Family"
                    subtitle="Creating a family will group all your recipes together and allow you to invite members to join!"
                    size={4}
                    textAlign="center"
                />
                <Grid container={true} spacing={3}>
                    <Grid item xs={12}>
                        <TextInput
                            error={FamilyNameError}
                            label="Family Name"
                            inputProps={{ maxLength: 30 }}
                            variant="outlined"
                            type="text"
                            name="FamilyName"
                            placeholder="Papa Bobby's Family"
                            value={FamilyName}
                            helperText={FamilyNameError ? "Family Name is required" : ""}
                            onChange={(event) => setFamilyName(event.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextArea
                            inputProps={{ maxLength: 500 }}
                            minRows={4}
                            variant="outlined"
                            label="About"
                            type="text"
                            multiline
                            name="about"
                            placeholder="We are a 3rd generation Italian family originally from Sicily. We love to cook and eat together!"
                            value={About}
                            onChange={(event) => setAbout(event.target.value)} />
                        <FormHelperText>Give a small snippet about your family!</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel id="FamilyOriginsInput">Family Origins</InputLabel>
                        <MultipleSelects
                            label="Family Origins"
                            multiple
                            value={familyOrigin}
                            renderValue={(selected) => selected.map(value => origins.find(([enumValue]) => enumValue === value)[1]).join(', ')}
                        >
                            {origins.map(([enumValue, displayName]) => (
                                <MenuItem key={enumValue} value={enumValue}>
                                    <ListItemText primary={displayName} />
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            checked={familyOrigin.includes(enumValue)}
                                            onChange={(event) => {
                                                const checked = event.target.checked;
                                                setFamilyOrigin(prevOrigins =>
                                                    checked
                                                        ? [...prevOrigins, enumValue]
                                                        : prevOrigins.filter(item => item !== enumValue)
                                                );
                                            }}
                                        />
                                    </ListItemSecondaryAction>
                                </MenuItem>
                            ))}
                        </MultipleSelects>
                    </Grid>

                    <StyledGridItem item xs={12}>
                        <SubmitButton variant="contained" color="secondary" onClick={() => createRecipeOnSubmit()}>Create Family</SubmitButton>
                    </StyledGridItem>
                </Grid>
            </Container>
        </Section>
    );
}

export default withAuthenticator(FamilyCreateForm);