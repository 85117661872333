import React, { useState, useEffect } from "react";
import Meta from "../components/Meta";
import CircularProgress from "@mui/material/CircularProgress";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { getUser } from "../util/api";
import { getCurrentUser } from "aws-amplify/auth";
import UserEditForm from "../components/UserEditForm";

function UserEditPage(props) {

    return (
        <>
            <Meta title={"Recipe Roots - Edit User"} />
            <UserEditForm/>
        </>
    );
}

export default withAuthenticator(UserEditPage);