import React, { useState, useEffect } from "react";
import Meta from "../components/Meta";
import Typography from "@mui/material/Typography";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { getCurrentUser } from "aws-amplify/auth";
import { CircularProgress, Grid } from "@mui/material";
import Section from "../components/Section";
import Container from "@mui/material/Container";
import { getUserFamilyByUserId, getFamilyInvitesByInvitee, getFamilyById } from "../util/api";
import { createUserFamily, deleteFamilyInvite } from "../graphql/mutations";
import Button from "@mui/material/Button";
import SectionHeader from "../components/SectionHeader";
import { generateClient } from "aws-amplify/api";
import { styled } from "@mui/system";

const StyledSection = styled(Section)(({ theme }) => ({
  marginTop: "20px",
}));


function UserFamilyInvitesPage(props) {
  const [familyList, setFamilyList] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [invites, setInvites] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const authUser = await getCurrentUser();
      let invitations = await getFamilyInvitesByInvitee(authUser.userId);
      for (let i = 0; i < invitations.length; i++) {
        const family = await getFamilyById(invitations[i].familyID);
        invitations[i].name = family.name;
        invitations[i].owner = family.owner;
        invitations[i].familyID = family.id;
        invitations[i].familyOwnerId = invitations[i].creator;
      }
      const currentFamilyList = await getUserFamilyByUserId(authUser.userId);
      setFamilyList(currentFamilyList);
      //iterate through removing an invite if the user is already in that family
      for (let i = 0; i < currentFamilyList.length; i++) {
        for (let j = 0; j < invitations.length; j++) {
          if (currentFamilyList[i].familyID === invitations[j].familyID) {
            invitations.splice(j, 1);
          }
        }
      }
      setInvites(invitations);
      setLoading(false);
    }

    fetchData();
  }, []);

  async function joinFamily(familyId, inviteId, familyOwnerId) {
    const client = generateClient();
    const authUser = await getCurrentUser();
    const model = {
      familyID: familyId,
      userID: authUser.userId,
      familyOwner: familyOwnerId, //THIS IS WHERE FAMILY OWNER ID GOES
    };
    const response = await client.graphql({ query: createUserFamily.replaceAll("__typename", ""), variables: { input: model } });
    if (response.errors) {
      console.log(response.errors);
      return;
    }
    console.log(inviteId)
    //now delete the invite
    const deleteInviteResponse = await client.graphql({ query: deleteFamilyInvite.replaceAll("__typename", ""), variables: { input: { id: inviteId } } });
    if (deleteInviteResponse.errors) {
      console.log(deleteInviteResponse.errors);
      return;
    }
    window.location.href = "/dashboard/1";
  }



  return (
    <>
      <Meta title="Recipe Roots - Invitations" />
      <StyledSection size="appBar">
        <Container>
          <SectionHeader
            title="Family Invitations"
            subtitle="Click to join a family, Add Recipes after you have joined by editing the recipe and selecting the family."
            size={4}
            textAlign="center"
          />
          {loading ? (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {invites.map((family) => {
                return (
                  <Grid container direction="column" key={family.name} style={{ borderBotom: '1px solid #000' }}>
                    <Grid item xs={12}>
                      <Typography variant="h6" component="h2">
                        {family.name}
                      </Typography>
                      <Typography variant="body2" component="p">
                        Author: {family.owner}
                      </Typography>
                    </Grid>
                    <Button variant="contained" color="primary" onClick={() => { joinFamily(family.familyID, family.id, family.familyOwnerId) }}>Join {family.name}</Button>
                  </Grid>
                )
              })}
            </>
          )}
        </Container>
      </StyledSection>

    </>
  );
}

export default withAuthenticator(UserFamilyInvitesPage);
