import React from "react";
import { Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import UserCard from "./UserCard";

const StyledSection = styled(Section)(({ theme }) => ({
  marginTop: "20px",
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: "100%",
  justifyContent: "center",
}));

function UserList(props) {
  return (
    <StyledSection
      bgColor={props.bgColor}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <StyledGrid container={true} spacing={2}>
            {props?.users?.map((user, index) => (
              <Grid item key={index}>
                <UserCard
                user={user}/>
              </Grid>
            ))}
          </StyledGrid>
      </Container>
    </StyledSection>
  );
}

export default UserList;