import React, { useState, useEffect } from "react";
import Meta from "../components/Meta";
import { useLocation } from 'react-router-dom';
import { getUser } from "../util/api";
import CircularProgress from "@mui/material/CircularProgress";
import UserPublicSection from "../components/UserPublicSection";
import { getRecipesByUser, getUserFamilyByUserId, getFamilyById } from "../util/api";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RecipeList from "../components/RecipeList";
import FamilyList from "../components/FamilyList";
import Section from "../components/Section";
import Container from "@mui/material/Container";
import RecipeLikesUser from "../components/RecipeLikesUser";

function UserViewPage(props) {
    const [user, setUser] = useState(null);
    const [recipes, setRecipes] = useState([]); // [recipe1, recipe2, recipe3, ...]
    const [families, setFamilies] = useState([]); // [family1, family2, family3, ...]
    const [tabValue, setTabValue] = useState(0);
    const { search } = useLocation();
    const [loading, setLoading] = useState(true);
    const query = new URLSearchParams(search);
    const id = query.get('id');


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const user = await getUser(id);
            setUser(user);
            const recipes = await getRecipesByUser(id + "::" + user.username);
            setRecipes(recipes);
            const userFamilies = await getUserFamilyByUserId(id);
            const families = await Promise.all(userFamilies.map(async (family) => {
                return await getFamilyById(family.familyID);
            }));
            setFamilies(families);
            setLoading(false);
        }
        fetchData();
    }, [id]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    if (loading) {
        return <CircularProgress />
    }

    return (
        <Section size="appBar">
            <Meta title={"Recipe Roots - " + user.username} />
            <UserPublicSection user={user} public={true} />
            <Container>
                <Section>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="user tabs" centered>
                        <Tab label="Recipes" />
                        <Tab label="Families" />
                        <Tab label="Likes" />
                    </Tabs>
                    {tabValue === 0 && <RecipeList recipes={recipes} size="auto" />}
                    {tabValue === 1 && <FamilyList families={families} />}
                    {tabValue === 2 && <RecipeLikesUser user={user} />}
                </Section>
            </Container>
        </Section>
    );
}

export default UserViewPage;
