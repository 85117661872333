import React from "react";
import { Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import Section from "./Section";
import SadChef from "../images/fatSadChef.png";
import Typography from "@mui/material/Typography";



const ImageContainer = styled('div')(({ theme }) => ({
    margin: "0 auto",
    maxWidth: "100%",
    marginBottom: "5px",
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    "& img": {
        borderRadius: "10px",
        height: "350px", // Set the height you want here
    },
}));

function ErrorSection(props) {
    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
        >
            <Container>
                <Typography variant="h4" align="center" gutterBottom>
                    {props.title}
                </Typography>
                <Typography variant="h6" align="center" gutterBottom>
                    {props.subtitle}
                </Typography>
                <ImageContainer>
                    <img src={SadChef} alt="Sad Chef" />
                </ImageContainer>
                
            </Container>
        </Section>
    );
}

export default ErrorSection;