import React, { useState, useEffect } from 'react';
import CreateProfile from './TutorialComponents/CreateProfile';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import CreateFamily from './TutorialComponents/CreateFamily';
import InviteMembers from './TutorialComponents/InviteMembers';
import bgImage from '../images/RecipeRootsBackground.jpg';
import Intro from './TutorialComponents/Intro';

const StyledContainer = styled(Container)(({ theme, divHeight }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: divHeight,
    padding: theme.spacing(3),
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: '10px',
    marginBottom: theme.spacing(1),
}));

const Dot = styled('span')(({ theme }) => ({
    fontSize: '25px',
    cursor: 'pointer',
    userSelect: 'none',
    color: theme.palette.primary.main,
}));

const DotWrapper = styled('div')({
    position: 'absolute',
    bottom: '10px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const CircularProgressWrapper = styled('div')({
    position: 'absolute',
    top: '10%',
    right: '5%',
    transform: 'translate(-50%, -50%)',
});

// const SlideWrapper = styled('div')(({ theme, index, currentSlide }) => ({
//     position: 'absolute',
//     width: '100%',
//     transform: index === currentSlide ? 'translateX(0)' : (index < currentSlide ? `translateX(${-2 * (currentSlide - index)}%)` : `translateX(${2 * (index - currentSlide)}%)`),
//     zIndex: 4 - Math.abs(currentSlide - index),
//     transition: theme.transitions.create(['transform', 'zIndex']),
// }));

const SlideWrapper = styled('div')(({ theme, index, currentSlide, totalSlides }) => ({
    position: 'absolute',
    width: '100%',
    display: currentSlide === totalSlides - 1 && index !== totalSlides - 1 ? 'none' : 'block',
    transform: index === currentSlide ? 'translateX(0)' : (index < currentSlide ? `translateX(${-2 * (currentSlide - index)}%)` : `translateX(${2 * (index - currentSlide)}%)`),
    zIndex: 4 - Math.abs(currentSlide - index),
    transition: theme.transitions.create(['transform', 'zIndex']),
}));




// Define your components
const Slide1 = () => <Intro/>;
const Slide2 = () => <CreateProfile />;
const Slide3 = () => <CreateFamily/>;
const Slide4 = () => <InviteMembers />;
const Slide5 = () => <></>;

function TutorialSlider() {
    const [progress, setProgress] = React.useState(0);

    // Create an array of slides
    const slides = [<Slide1 />, <Slide2 />, <Slide3 />, <Slide4 />, <Slide5 />];

    // Create a state variable for the current slide
    const [currentSlide, setCurrentSlide] = useState(0);
    const [prevSlide, setPrevSlide] = useState(null);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
            setProgress(0);
        }, 10000);
        return () => clearInterval(timer); // Clean up the timer when the component unmounts
    }, []);


    useEffect(() => {
        // Update the previous slide whenever the current slide changes
        setPrevSlide(currentSlide);
        // Start a new timer that updates the progress every second
        const progressTimer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress >= 100) {
                    clearInterval(progressTimer);
                    return 100;
                }
                return oldProgress + 1; // Increment the progress by 10 every second
            });
        }, 100);
        return () => clearInterval(progressTimer); // Clean up the timer when the component unmounts
    }, [currentSlide]);

    return (
        <StyledContainer divHeight={'45vh'}>
            {slides.map((slide, i) => (
                <SlideWrapper key={i} index={i} currentSlide={currentSlide} totalSlides={slides.length}>
                    <div>
                        {slide}
                    </div>
                </SlideWrapper>
            ))}
            <CircularProgressWrapper>
                <CircularProgress variant="determinate" value={progress} />
            </CircularProgressWrapper>
            <DotWrapper>
                {slides.map((_, i) => (
                    <Dot key={i} onClick={() => setCurrentSlide(i)}>
                        {i === currentSlide ? '● ' : '○ '}
                    </Dot>
                ))}
            </DotWrapper>
        </StyledContainer>
    );
}

export default TutorialSlider;