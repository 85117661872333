import react, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getUser, getFamilyById, getInviteLink, getUserFamilyByUserId } from "../util/api";
import { getCurrentUser } from 'aws-amplify/auth';
import { createUserFamily } from "../graphql/mutations";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { generateClient } from 'aws-amplify/api';
import { Divider, FormControlLabel, Typography, Checkbox, FormGroup } from "@mui/material";
import { withAuthenticator } from '@aws-amplify/ui-react';
import Section from "../components/Section";
import Container from "@mui/material/Container";
import Meta from "../components/Meta";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

function InviteLinkPage(props) {
    const [user, setUser] = useState(null);
    const [selectedFamilies, setSelectedFamilies] = useState([]);

    const [loading, setLoading] = useState(true);
    const [inviter, setInviter] = useState(null);
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [saved, setSaved] = useState(false);
    const { search } = useLocation();
    const [invite, setInvite] = useState(null);
    const query = new URLSearchParams(search);
    const id = query.get('inviteID');

    useEffect(() => {

        async function fetchData() {
            setLoading(true);
            const authUser = await getCurrentUser();
            const userData = await getUser(authUser.userId);
            setUser(userData);
            if (!id) {
                setLoading(false);
                setError(true);
                window.location.href = "/";
                return;
            }
            try {
                const inviteData = await getInviteLink(id);
                console.log(inviteData);
                for (let i = 0; i < inviteData.familyIds.length; i++) {
                    const familyData = await getFamilyById(inviteData.familyIds[i]);
                    inviteData.familyIds[i] = familyData;
                }
                setInvite(inviteData);
                console.log(inviteData)
                //get the user who invited them
                const inviter = await getUser(inviteData.userID);
                setInviter(inviter);
                setLoading(false);
            }
            catch (error) {
                console.log(error);
                setError(true);
                setLoading(false);
            }

        }

        fetchData();
    }, []);

    const handleCheckboxChange = (event, family) => {
        if (event.target.checked) {
            setSelectedFamilies(prev => [...prev, family]);
        } else {
            setSelectedFamilies(prev => prev.filter(f => f.id !== family.id));
        }
    };

    async function getStarted() {
        const authUser = await getCurrentUser();
        //lets check firs to see if this user is already in this family

        const currentFamilyList = await getUserFamilyByUserId(authUser.userId);
        for (let i = 0; i < currentFamilyList.length; i++) {
            for(let j = 0; j < selectedFamilies.length; j++){
                if (currentFamilyList[i].familyID === selectedFamilies[j].id) {
                    setAlert("You are already a member of " + selectedFamilies[j].name);
                    setAlertOpen(true);
                    return;
                }
            }
        }
        for (let i = 0; i < selectedFamilies.length; i++) {
            await joinFamily(selectedFamilies[i].id, authUser);
        }
        window.location.href = "/dashboard/0";

    }

    async function joinFamily(familyID, user) {        
        const client = generateClient();
        const model = {
            familyID: familyID,
            userID: user.userId,
            familyOwner: invite.userID //This is the owner of the family, because they sent the invite
        };
        const response = await client.graphql({ query: createUserFamily.replaceAll("__typename", ""), variables: { input: model } });
        if (response.errors) {
            console.log(response.errors);
            setAlert("An error occurred while joining the family. Please try again later.");
            return;
        }
    }

    return (
        <>
            <Meta title="Recipe Roots - Your Invitation" />
            <Container>
                <Section size="appBar">
                    {alertOpen && <Alert severity="error" onClose={() => setAlertOpen(false)}>
                        <AlertTitle>Error</AlertTitle>
                        {alert}
                    </Alert>}
                    {!loading && !error && invite && invite.familyIds.length > 0 &&
                        <>
                            <Typography variant="h4" gutterBottom>
                                {inviter.username} has invited you to join their family!
                            </Typography>
                            <Typography variant="body1">
                                {invite.about}
                            </Typography>
                            <Grid container={true} spacing={3} style={{ marginTop: '8px' }}>
                                {invite.familyIds.length > 0 &&
                                    <Grid item xs={12} >
                                        <Typography variant="body1" component="p" style={{ marginBottom: '8px' }}>
                                            You have been invited to join the following families. Please select the ones you would like to join and click "Get started" to begin.
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={12} >
                                    <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                        {invite.familyIds.map((family) => (
                                            <FormControlLabel
                                                key={family.id}
                                                value={family.id}
                                                control={<Checkbox
                                                    checked={selectedFamilies.some(f => f.id === family.id)}
                                                    onChange={(event) => handleCheckboxChange(event, family)}
                                                />}
                                                label={family.name} />
                                        ))
                                        }

                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} >
                                    <Button variant="contained" color="secondary" onClick={() => getStarted()}>
                                        Get started
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    }
                    {!loading && !error && invite && invite.familyIds.length === 0 &&
                        <div style={{ marginTop: '8px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h4" gutterBottom>
                                {inviter.username} has invited you to join Recipe Roots!
                            </Typography>
                            <Typography variant="body1">
                                {invite.about}
                            </Typography>
                            <Button variant="contained" color="secondary" onClick={() => window.location.href = "/dashboard/0"}>
                                Get started
                            </Button>
                        </div>
                    }
                </Section>
            </Container >
        </>
    );
}

export default withAuthenticator(InviteLinkPage);
