import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Chip, Container, Grid, Paper, Typography, CircularProgress, Box, Divider } from "@mui/material";
import Section from "./Section";
import { getRecipe, getFamilyById, getLikesByUser } from "../util/api";
import { toggleLikeOnRecipe } from "../util/common";
import { getRecipeTagsDisplayValue } from "../util/constants";
import stockPhoto from "../images/stockRecipe.jpg";
import { styled } from "@mui/system";
import IconButton from '@mui/material/IconButton';
import { FacebookShareButton, TwitterShareButton, PinterestShareButton } from "react-share";
import { Facebook, X, Pinterest } from "@mui/icons-material";
import { getCurrentUser } from 'aws-amplify/auth';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite'; // I
import ErrorSection from "./ErrorSection";
import EditIcon from '@mui/icons-material/Edit';

const ImageContainer = styled('div')(({ theme }) => ({
    margin: "0 auto",
    maxWidth: "100%",
    marginBottom: "5px",
    "& img": {
        width: "100%",
        borderRadius: "10px",
        height: "350px", // Set the height you want here
        objectFit: "cover", // This will prevent distortion
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
}));


const StyledChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const StyledSection = styled(Section)(({ theme }) => ({
    marginTop: "20px",
    paddingTop: "0px",
}));



function RecipeView(props) {
    const { search } = useLocation();
    const [recipe, setRecipe] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [user, setUser] = useState(null);
    const [liked, setLiked] = useState(false);
    const [likeId, setLikeId] = useState(null);
    const [recipeLikeCount, setRecipeLikeCount] = useState(0);
    const [family, setFamily] = useState(null);
    const [owner, setOwner] = useState(false);

    // Access query parameters
    const query = new URLSearchParams(search);
    const id = query.get('id');

    useEffect(() => {
        async function fetchData() {
            if (!id) {
                setError(true)
                setLoading(false);
                return;
            }
            try {
                const user = await getCurrentUser();
                setUser(user);
                if (!user) return;
                const likes = await getLikesByUser(user.userId);
                const like = likes.find(like => like.recipeID === id);
                if (like) {
                    setLiked(true);
                    setLikeId(like.id);
                }
            }
            catch (error) {
                //No actual error, there is just no signed in user
                console.log('error getting user likes: ', error);
            }
            const responseRecipe = await getRecipe(id);
            console.log(responseRecipe.imageUrl)
            //check if user is owner
            if (user) {
                const owner = user.username === responseRecipe.owner;
                setOwner(owner);
            }
            if (!responseRecipe) {
                setError(true);
                setLoading(false);
                return;
            }
            setRecipe(responseRecipe);
            setRecipeLikeCount(responseRecipe.likeCount);

            //check to see if recipe has family, if it does, get the family
            if (responseRecipe.familyID !== 0) {
                const responseFamily = await getFamilyById(responseRecipe.familyID);
                setFamily(responseFamily);
            }

            setLoading(false);

        }
        fetchData();
    }, [id]);


    const setLikeOnRecipe = async () => {
        try {
            const newLikeId = await toggleLikeOnRecipe(liked, likeId, id, user);
            if (newLikeId === 0) {
                setLiked(false);
                setRecipeLikeCount(recipeLikeCount - 1);
                return;
            }
            setLiked(true);
            setLikeId(newLikeId);
            setRecipeLikeCount(recipeLikeCount + 1);

        } catch (error) {
            console.log('error setting like on recipe: ', error);
            setError(true);
        }
    }

    function getEnumFromDisplay(displayValue) {
        for (const enumValue in getRecipeTagsDisplayValue) {
            if (enumValue === displayValue) {
                return getRecipeTagsDisplayValue[enumValue];
            }
        }
        return null; // Return null if displayValue not found
    }

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <ErrorSection title="Error loading recipe" />;
    }

    return (
        <StyledSection bgColor={props.bgColor} size={props.size}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4">{recipe.title}
                        {owner &&
                            <IconButton component={Link} to={"/recipeEdit?recipeId=" + recipe.id}>
                                <EditIcon />
                            </IconButton>
                        }
                        </Typography>
                        {family && <Typography variant="subtitle2">From, <Link to={`/family?id=${family.id}`}>{family.name}</Link></Typography>}
                        <Typography variant="subtitle2">Created by, <Link to={`/user?id=${recipe.userID.split("::")[0]}`}>{recipe.owner}</Link></Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 150 }}>
                            <IconButton size="small">
                                <FacebookShareButton url={window.location.href} quote={recipe.title}>
                                    <Facebook />
                                </FacebookShareButton>
                            </IconButton>
                            <IconButton size="small">
                                <TwitterShareButton url={window.location.href} title={recipe.title}>
                                    <X />
                                </TwitterShareButton>
                            </IconButton>
                            <IconButton size="small">
                                <PinterestShareButton url={window.location.href} media={recipe.imageUrl ? recipe.imageUrl : stockPhoto} description={recipe.title}>
                                    <Pinterest />
                                </PinterestShareButton>
                            </IconButton>
                        </Box>
                        <IconButton onClick={() => setLikeOnRecipe()}>
                            {liked ? <FavoriteIcon color="secondary" /> : <FavoriteBorderIcon color="secondary" />}
                        </IconButton>

                        <Typography variant="subtitle1" component="span">{recipeLikeCount}</Typography>
                        <br />
                        {recipe.tags?.map((tag) => (
                            <StyledChip key={getEnumFromDisplay(tag)} label={getEnumFromDisplay(tag)}></StyledChip>
                        ))}
                        <br />
                        <Typography variant="body1">{recipe.about}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ImageContainer>
                            <img src={recipe.imageUrl ? recipe.imageUrl : stockPhoto} alt={recipe.title} />
                        </ImageContainer>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                            <Typography variant="subtitle1">Prep: <span style={{ fontWeight: 'bold' }}>{recipe.prepTime} min.</span></Typography>
                            <Typography variant="subtitle1">Cook: <span style={{ fontWeight: 'bold' }}>{recipe.cookTime} min.</span></Typography>
                            <Typography variant="subtitle1">Servings: <span style={{ fontWeight: 'bold' }}>{recipe.servings ? recipe.servings : "N/A"}</span></Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Item>
                            <Typography variant="h5">Ingredients</Typography>
                            {recipe.ingredients?.map((ingredient, index) => (
                                <>
                                    <Typography variant="body1" key={index}>{ingredient}</Typography>
                                    <Divider />
                                </>
                            ))}

                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Item>
                            <Typography variant="h5">Instructions</Typography>
                            {recipe.instructions?.map((instruction, index) => (
                                <Typography variant="body1" key={index}>
                                    <Box fontWeight="fontWeightBold" component="span">{index + 1 + ". "}</Box> {instruction}
                                </Typography>
                            ))}
                        </Item>
                    </Grid>
                </Grid>
            </Container>
        </StyledSection>
    );
}

export default RecipeView;