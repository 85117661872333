import React from "react";
import Meta from "../components/Meta";
import FamilyAddMembersForm from "../components/FamilyAddMembersForm";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Section from "../components/Section";
function AddFamilyMembersPage(props) {

  return (
    <Section size="appBar">
    <Meta title="Recipe Roots - Invite"/>
      <FamilyAddMembersForm/>
    </Section>
    );
}

export default withAuthenticator(AddFamilyMembersPage);


