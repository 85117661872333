import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Typography from "@mui/material/Typography";
import RecipeCard from "./RecipeCard";
import { searchRecipesByMostRecent, searchRecipesByFamilyOrigin } from "../util/api";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Grow from '@mui/material/Grow';
import { getCurrentUser } from 'aws-amplify/auth';
import { getLikesByUser } from '../util/api';
const ScrollableGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gridAutoFlow: "row",
  height: "100%",
  overflowX: "auto",
  scrollBehavior: "smooth",
  flexWrap: "nowrap",
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  position: "relative",
  padding: 10,
  overflow: "hidden",
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1000, // Increase the zIndex
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
}));

const ScrollableGridContainer = styled('div')({
  position: 'relative',
});

function RecipeStreamList(props) {
  const gridRef = useRef(); // add this line
  const [recipes, setRecipes] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [userLikes, setUserLikes] = useState([]);
  const scrollLeft = () => {
    if (gridRef.current) {
      gridRef.current.scrollLeft -= gridRef.current.offsetWidth-175;
    }
  };

  const scrollRight = () => {
    if (gridRef.current) {
      gridRef.current.scrollLeft += gridRef.current.offsetWidth-175;
    }
  };

  useEffect(() => {
    const fetchRecipes = async () => {
      setLoading(true);
      try {
        if (props.tag === "") {
          const recipes = await searchRecipesByMostRecent(20);
          setRecipes(recipes);
          setLoading(false);
          return;
        }
        const recipes = await searchRecipesByFamilyOrigin(props.tag, 20);
        setRecipes(recipes);
        setLoading(false);
      } catch (error) {
        console.log('error fetching recipes: ', error);
        setLoading(false);
      }
    }
    fetchRecipes();
  }, [JSON.stringify(props.tag)]);


  useEffect(() => {
    const fetchUserLikes = async () => {
      try {
        const user = await getCurrentUser();
        setUser(user);
        if (!user) return;
        const userLikes = await getLikesByUser(user.userId);
        setUserLikes(userLikes);
        setLoading(false);
      }
      catch (error) {
        console.log('error getting current user: ', error);
        setUserLikes([]);
        setLoading(false);
      }
    };
    fetchUserLikes();

  }, []);



  return (
    <StyledContainer>
      <Typography variant="h5" component="h2" gutterBottom>
        {props.title}
      </Typography>
      <ScrollableGridContainer
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && (
          <>
            <ArrowButton style={{ left: 0 }} onClick={scrollLeft}>
              <ArrowBackIosIcon />
            </ArrowButton>
            <ArrowButton style={{ right: 0 }} onClick={scrollRight}>
              <ArrowForwardIosIcon />
            </ArrowButton>
          </>
        )}
        {loading ? (
          <CircularProgress /> // Render CircularProgress if loading is true
        ) : (
          <ScrollableGrid ref={gridRef} container spacing={2}>
            {recipes.map((recipe, index) => (
              <Grow in={true} timeout={index * 500} key={index}>
                <Grid item key={index}>
                  <RecipeCard
                    recipe={recipe}
                    userRecipesOnly={false}
                    userLikes={userLikes}
                    user={user}
                  />
                </Grid>
              </Grow>
            ))}
          </ScrollableGrid>
        )}
      </ScrollableGridContainer>
    </StyledContainer>
  );
}

export default RecipeStreamList;