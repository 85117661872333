import React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Link } from  "react-router-dom";
import logo from "../../images/Recipe Roots Logo Symbol.png";
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: 'auto',
  maxWidth: 400,
  height: 225,
  textAlign: 'center', 
  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${logo})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    // marginTop: theme.spacing(2),
  }));

function CreateFamily(props) {
  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h3" gutterBottom align="center">
            Create a Family
          </Typography>
        </Grid>
        <Grid item xs={12} style={{paddingTop: '4px'}}>
          <Typography variant="body1" gutterBottom>
            Create your family profile and extend invitations to relatives to share cherished recipes and stories, preserving your cultural legacy. Together, let's savor traditions, one family recipe at a time.
          </Typography>
        </Grid>
        <Grid item xs={12} align="center" style={{paddingTop: '4px'}}>
          <StyledButton component={Link} to="/familyCreate" variant="contained" color="primary">
            Create a Family
          </StyledButton>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default CreateFamily;