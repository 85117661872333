import React, {useState, useEffect} from "react";
import Meta from "../components/Meta";
import CircularProgress from "@mui/material/CircularProgress";
import FamilyEditForm from "../components/FamilyEditForm";
import { withAuthenticator } from "@aws-amplify/ui-react";
import FamilyAddMembersForm from "../components/FamilyAddMembersForm";
import { getFamilyById, getFamilyMembers } from "../util/api";
import { useLocation } from "react-router-dom";

function FamilyEditPage(props) {
    const [family, setFamily] = useState({});
    const [members, setMembers] = useState([]);
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const id = query.get('id');

    useEffect(() => {
        const fetchData = async () => {
            const family = await getFamilyById(id);
            setFamily(family);
            const members = await getFamilyMembers(id);
            setMembers(members);
            //ensure that the family and members are fetched before rendering the form
        };    
        fetchData();
    }, []);

    if (family.name === undefined || !members) {
        return (<CircularProgress size={32} />);
    }
    return (
        <>
            <Meta title={"Edit Family"} />
            <FamilyEditForm 
            family={family}
            members={members}
            size="appBar"/>
            <FamilyAddMembersForm />
        </>
    );
}

export default withAuthenticator(FamilyEditPage);


