import { generateClient } from "aws-amplify/api";
import * as queries from '../graphql/queries';
import { getUrl } from "aws-amplify/storage";
import * as mutations from '../graphql/mutations';
import { remove } from 'aws-amplify/storage';
//Add Like to recipe
export const addLike = async (recipeId, userId) => {
  try {
    const client = generateClient();
    //first check to see if the user has already liked the recipe
    await getLikesByUser(userId).then((likes) => {
      for (let i = 0; i < likes.length; i++) {
        if (likes[i].recipeID === recipeId) {
          throw new Error('User has already liked this recipe');
        }
      }
    });

    const response = await client.graphql({
      query: mutations.createLike,
      variables: { input: { recipeID: recipeId, userID: userId } },
    });
    return response.data.createLike.id;
  } catch (error) {
    console.error('Error adding like:', error);
    throw error;
  }
};

//get likes to recipe
export const getLikes = async (recipeId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.likesByRecipeID,
      variables: { recipeID: recipeId },
      apiName: "recipeApi",
      authMode: "apiKey"
    });
    return response.data.likesByRecipeID.items;
  } catch (error) {
    console.error('Error getting likes:', error);
    throw error;
  }
};

//get likes by user
export const getLikesByUser = async (userId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.likesByUserID,
      variables: { userID: userId },
      authMode: "apiKey"
    });
    return response.data.likesByUserID.items;
  } catch (error) {
    console.error('Error getting likes by user:', error);
    throw error;
  }
};

//delete like
export const deleteLike = async (likeId) => {
  try {
    const client = generateClient();
    await client.graphql({
      query: mutations.deleteLike,
      variables: { input: { id: likeId } },
    });
  }
  catch (error) {
    console.error('Error deleting like:', error);
    throw error;
  }
};


//get object image
const getObjectImage = async (object) => {
  try {
    const image = await getUrl({ key: object.image });
    return image.url.href;
  } catch (error) {
    console.error('Error getting recipe image:', error);
    return null;
  }
};


//get recipe
export const getRecipe = async (recipeId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.getRecipe/* GraphQL query for getting a recipe by ID */,
      variables: { id: recipeId },
      authMode: "apiKey"
    });
    const recipe = response.data.getRecipe;
    if (!recipe) return null;
    if (recipe.image) {
      await getObjectImage(recipe).then((image) => {
        recipe.imageUrl = image;
      });
    }
    return recipe;
  } catch (error) {
    console.error('Error getting recipe:', error);
    throw error;
  }
};

//searchRecipesByMostRecent
export const searchRecipesByMostRecent = async (limit) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.searchRecipes,
      variables: { sort: { field: "createdAt", direction: "desc" }, limit: limit },
      authMode: "apiKey"
    });
    const recipes = response.data.searchRecipes.items;
    for (const recipe of recipes) {
      if (recipe.image) {
        await getObjectImage(recipe).then((image) => {
          recipe.imageUrl = image;
        });
      }
    }
    return recipes;
  } catch (error) {
    console.error('Error searching recipes:', error);
    throw error;
  }
};

//general search recipe
export const searchRecipes = async (searchQuery, tags, limit) => {
  try {
    const client = generateClient();

    let tagFilters = tags.map(tag => ({ tags: { matchPhrasePrefix: tag } }));
    let searchVariables = {};

    if (tags.length === 0) {
      searchVariables = { filter: { title: { matchPhrasePrefix: searchQuery } }, sort: { field: "likeCount", direction: "desc" }, limit: limit };
    }
    else {
      searchVariables = {
        filter: {
          and: [
            { or: [{ title: { matchPhrasePrefix: searchQuery } }] },
            ...tagFilters
          ]
        },
        sort: { field: "likeCount", direction: "desc" },
        limit: limit
      };
    }

    const response = await client.graphql({
      query: queries.searchRecipes/* GraphQL query for searching recipes */,
      variables: searchVariables,
      authMode: "apiKey"
    });
    const recipes = response.data.searchRecipes.items;
    for (const recipe of recipes) {
      if (recipe.image) {
        await getObjectImage(recipe).then((image) => {
          recipe.imageUrl = image;
        });
      }
    }
    return recipes;
  } catch (error) {
    console.error('Error searching recipes:', error);
    throw error;
  }
}

//search by family Origin
export const searchRecipesByFamilyOrigin = async (origins, limit) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.searchFamilies,
      variables: { filter: { origins: { matchPhrasePrefix: origins } }, sort: { field: "createdAt", direction: "desc" } },
      limit: limit,
      authMode: "apiKey"
    });

    const families = response.data.searchFamilies.items;
    let recipes = [];
    for (const family of families) {
      const familyRecipes = await getFamilyRecipes(family.id);

      // Randomly select a specific amount of recipes from familyRecipes
      const amountToSelect = Math.min(familyRecipes.length, 5);
      for (let i = 0; i < amountToSelect; i++) {
        const randomIndex = Math.floor(Math.random() * familyRecipes.length);
        recipes.push(familyRecipes[randomIndex]);
        familyRecipes.splice(randomIndex, 1); // Remove the selected recipe from familyRecipes 
      }
      if (recipes.length > 50) break;
    }

    return recipes;
  }
  catch (error) {
    console.error('Error searching recipes:', error);
    throw error;
  }
};

//delete Recipe
export const deleteRecipe = async (recipeId) => {
  try {
    const client = generateClient();
    //get recipe
    const recipe = await getRecipe(recipeId);


    await client.graphql({
      query: mutations.deleteRecipe,
      variables: { input: { id: recipeId } },
    });
    //delete the recipe likes
    await getLikes(recipeId).then((likes) => {
      for (let i = 0; i < likes.length; i++) {
        deleteLike(likes[i].id);
      }
    });

    //delete the image
    if (recipe.image)
      await remove(recipe.image);


  } catch (error) {
    console.error('Error deleting recipe:', error);
    throw error;
  }
};

//getRecipesByUser
export const getRecipesByUser = async (userId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.recipesByUserID/* GraphQL query for getting recipes by user ID */,
      variables: { userID: userId },
      authMode: "apiKey"
    });
    const recipes = response.data.recipesByUserID.items;
    for (const recipe of recipes) {
      if (recipe.image) {
        await getObjectImage(recipe).then((image) => {
          recipe.imageUrl = image;
        });
      }
    }
    return recipes;
  } catch (error) {
    console.error('Error getting recipes by user:', error);
    throw error;
  }
};

//getUser
export const getUser = async (userId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.getUser/* GraphQL query for getting a user by ID */,
      variables: { id: userId },
      authMode: "apiKey"
    });
    const user = response.data.getUser;
    if(user === null) return null;
    if (user.image !== null) {
      await getObjectImage(user).then((image) => {
        user.imageUrl = image;
      });
    }
    return user;
  }
  catch (error) {
  console.error('Error getting user:', error);
  throw error;
}
};

//searchUsers
export const searchUsers = async (searchQuery, limit) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.searchUsers/* GraphQL query for searching users */,
      variables: { filter: { 
        or: [
            { username: { matchPhrasePrefix: searchQuery } },
            { name: { matchPhrasePrefix: searchQuery } }
        ]
    },
       limit: limit },
      authMode: "apiKey"
    });

    //get image for each user
    for (let i = 0; i < response.data.searchUsers.items.length; i++) {
      if (response.data.searchUsers.items[i].image !== null) {
        await getObjectImage(response.data.searchUsers.items[i]).then((image) => {
          response.data.searchUsers.items[i].imageUrl = image;
        });
      }
    }

    return response.data.searchUsers.items;
  } catch (error) {
    console.error('Error searching users:', error);
    throw error;
  }
};


export const searchUsersByUsername = async (searchQuery, limit) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.searchUsers/* GraphQL query for searching users */,
      variables: { filter: { username: { eq: searchQuery } },
       limit: limit },
      authMode: "apiKey"
    });

    //get image for each user
    for (let i = 0; i < response.data.searchUsers.items.length; i++) {
      if (response.data.searchUsers.items[i].image !== null) {
        await getObjectImage(response.data.searchUsers.items[i]).then((image) => {
          response.data.searchUsers.items[i].imageUrl = image;
        });
      }
    }

    return response.data.searchUsers.items;
  } catch (error) {
    console.error('Error searching users:', error);
    throw error;
  }
};

//getFamilyById
export const getFamilyById = async (familyId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.getFamily/* GraphQL query for getting a family by ID */,
      variables: { id: familyId },
      authMode: "apiKey"
    });
    return response.data.getFamily;
  } catch (error) {
    console.error('Error getting family:', error);
    throw error;
  }
};

//get UserFamily by UserId
export const getUserFamilyByUserId = async (userId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.userFamiliesByUserID,
      variables: { userID: userId },
      authMode: "apiKey"
    });
    return response.data.userFamiliesByUserID.items;
  } catch (error) {
    console.error('Error getting user family by user ID:', error);
    throw error;
  }
};

//getUserFamilyByFamilyID
export const getUserFamilyByFamilyID = async (familyId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.userFamiliesByFamilyID,
      variables: { familyID: familyId },
    });
    return response.data.userFamiliesByFamilyID.items;
  } catch (error) {
    console.error('Error getting user family by family ID:', error);
    throw error;
  }
};

//getFamilyMembers
export const getFamilyMembers = async (familyId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.userFamiliesByFamilyID /* GraphQL query for getting UserFamily records by family ID */,
      variables: { familyID: familyId },
      authMode: "apiKey"
    });

    // Get the User records for each UserFamily
    const userPromises = response.data.userFamiliesByFamilyID.items.map(async userFamily => {
      const userResponse = getUser(userFamily.userID);
      return userResponse;
    });

    // Wait for all User records to be fetched
    const users = await Promise.all(userPromises);

    return users;
  } catch (error) {
    console.error('Error getting family members:', error);
    throw error;
  }
};

//getFamilyRecipes
export const getFamilyRecipes = async (familyId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.recipesByFamilyID/* GraphQL query for getting recipes by family ID */,
      variables: { familyID: familyId },
      authMode: "apiKey"
    });
    const recipes = response.data.recipesByFamilyID.items;
    for (const recipe of recipes) {
      if (recipe.image) {
        await getObjectImage(recipe).then((image) => {
          recipe.imageUrl = image;
        });
      }
    }
    return recipes;
  } catch (error) {
    console.error('Error getting family recipes:', error);
    throw error;
  }
};

//searchFamily
export const searchFamily = async (searchQuery, origins, limit) => {
  try {
    const client = generateClient();

    let tagFilters = origins.map(origin => ({ origins: { matchPhrasePrefix: origin } }));
    let searchVariables = {};

    if (origins.length === 0) {
      searchVariables = {
        filter: {
          or: [
            { name: { matchPhrasePrefix: searchQuery } },
            { origins: { matchPhrasePrefix: searchQuery } }
          ]
        },
        limit: limit
      };
    }
    else if (origins.length > 0) {
      searchVariables = {
        filter: {
          and: [
            { or: [{ name: { matchPhrasePrefix: searchQuery } }, { origins: { matchPhrasePrefix: searchQuery } }] },
            ...tagFilters
          ]
        },
        limit: limit
      };
    }

    const response = await client.graphql({
      query: queries.searchFamilies/* GraphQL query for searching families */,
      variables: searchVariables,
      authMode: "apiKey"
    });
    return response.data.searchFamilies.items;
  } catch (error) {
    console.error('Error searching families:', error);
    throw error;
  }
};

//getFamilyInvitesByCreator
export const getFamilyInvitesByFamilyID = async (familyId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.familyInvitesByFamilyID/* GraphQL query for getting family invites by creator */,
      variables: { familyID: familyId },
    });
    return response.data.familyInvitesByFamilyID.items;
  } catch (error) {
    console.error('Error getting family invites by familyId:', error);
    throw error;
  }
};

//getFamilyInvitesByInvitee
export const getFamilyInvitesByInvitee = async (invitee) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.familyInvitesByInvitee/* GraphQL query for getting family invites by invitee */,
      variables: { invitee: invitee },
    });
    return response.data.familyInvitesByInvitee.items;
  } catch (error) {
    console.error('Error getting family invites by invitee:', error);
    throw error;
  }
};

//deleteFamilyInvite
export const deleteFamilyInvite = async (inviteId) => {
  try {
    const client = generateClient();
    await client.graphql({
      query: mutations.deleteFamilyInvite,
      variables: { input: { familyInviteId: inviteId } },
    });
  } catch (error) {
    console.error('Error deleting family invite:', error);
    throw error;
  }
};

//getInviteLink
export const getInviteLink = async (inviteId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: queries.getUserInviteLink,
      variables: { id: inviteId },
    });
    return response.data.getUserInviteLink;
  } catch (error) {
    console.error('Error getting invite link:', error);
    throw error;
  }
};