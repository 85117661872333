import React from "react";
import { createRoot } from "react-dom/client";
// import App from "./pages/_app";
import App from "./app"
import * as serviceWorker from "./serviceWorker";
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import ErrorBoundary from "./components/ErrorBoundary";

Amplify.configure(config);

const root = document.getElementById("root");
if (root !== null) {
    // createRoot(root).render(<ErrorBoundary><App /></ErrorBoundary>);
    createRoot(root).render(<App />);
}
  
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
