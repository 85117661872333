import react, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Section from '../components/Section';
import { styled } from "@mui/system";
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { FacebookShareButton, TwitterShareButton, PinterestShareButton } from "react-share";
import { Facebook, X, Pinterest } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import Edit from '@mui/icons-material/Edit';
import KoFiLink from './KoFiLink';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { getFamilyInvitesByInvitee, getUserFamilyByUserId } from '../util/api';
import { CircularProgress } from '@mui/material';
import UserCreateSection from './UserCreateSection';

const EditIcon = styled('div')({
    float: "right",
});

const StyledSection = styled(Section)(({ theme }) => ({
    marginTop: "20px",
    marginBottom: theme.spacing(2),
}));

const AvatarWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(10),
    height: theme.spacing(10),
}));

function UserPublicSection(props) {
    const [familyList, setFamilyList] = useState([]); // [family1, family2, family3, ...]
    const [loading, setLoading] = useState(true);
    const [invites, setInvites] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let invitations = await getFamilyInvitesByInvitee(props.user.id);
            setInvites(invitations);
            const currentFamilyList = await getUserFamilyByUserId(props.user.id);
            setFamilyList(currentFamilyList);
            //iterate through removing an invite if the user is already in that family
            for (let i = 0; i < currentFamilyList.length; i++) {
                for (let j = 0; j < invitations.length; j++) {
                    if (currentFamilyList[i].familyID === invitations[j].familyID) {
                        invitations.splice(j, 1);
                    }
                }
            }
            setInvites(invitations);
            setLoading(false);
        }
        if(props.user && !props.public){
            fetchData();
        }
        else{
            setLoading(false);            
        }
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <StyledSection bgColor={props.bgColor} size="auto">
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <AvatarWrapper>
                                <StyledAvatar alt={props.user.username} src={props.user.imageUrl} />
                            </AvatarWrapper>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Typography variant="h5">{props.user.username}</Typography>
                            {props.user.name !== null &&
                                <Typography variant="subtitle1">{props.user.name}</Typography>
                            }
                            <Typography variant="subtitle1">{props.user.about}</Typography>
                            {props.public
                                ?
                                <>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100px'}}>
                                    <IconButton size="small">
                                        <FacebookShareButton url={window.location.href} quote={props.user.username}>
                                            <Facebook />
                                        </FacebookShareButton>
                                    </IconButton>
                                    <IconButton size="small">
                                        <TwitterShareButton url={window.location.href} title={props.user.username}>
                                            <X />
                                        </TwitterShareButton>
                                    </IconButton>
                                </Box>
                                    <KoFiLink user={props.user} public={true}/>
                                </>
                                
                                :
                                <UserCreateSection user={props.user} invites={invites} />
                            }
                        </Grid>
                    </Grid>
                </Container>
            </StyledSection >
            <Divider variant="inset" />
        </>
    );
}

export default UserPublicSection;