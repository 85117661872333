import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Meta from "./../components/Meta";
import RecipeList from "./../components/RecipeList";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { getCurrentUser } from "aws-amplify/auth";
import { CircularProgress, Typography } from "@mui/material";
import Section from "../components/Section";
import Container from "@mui/material/Container";
import { getUser, getRecipesByUser, getUserFamilyByUserId, getFamilyById } from "../util/api";
import ErrorSection from "../components/ErrorSection";
import UserPublicSection from "../components/UserPublicSection";
import FamilyList from "../components/FamilyList";
import { Tab, Tabs } from "@mui/material";
import ReactJoyride, { STATUS } from 'react-joyride';
import RecipeLikesUser from "../components/RecipeLikesUser";
import InviteLinkDialog from "../components/InviteLinkDialog";


const steps = [
  {
    target: '.ActionItems',
    content: 'Click the dropdown to create a new recipe, a new family, or to view family invites.',
  },
  {
    target: '.EditButton',
    content: 'Click the Edit Icon to adjust your profile information or leave a family.',
  },
  {
    target: '.koFiLink',
    content: 'This is where you can setup a donation link, so that other members can support you!',
  },
  {
    target: '.linkButton',
    content: 'Click here to get a link to invite others to join your family! Or simply tell them to get started!',
  }
  // More steps...
];

function DashboardPage(props) {
  const [recipes, setRecipes] = useState([]);
  const [familyList, setFamilyList] = useState([]); // [family1, family2, family3, ...]
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  let { tab } = useParams();
  //convert tab to int
  tab = parseInt(tab);
  const [tabValue, setTabValue] = useState(tab || 0);
  const [tutorial, setTutorial] = useState(false);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      setLoading(true);
      const authUser = await getCurrentUser();
      const userData = await getUser(authUser.userId);
      const recipeData = await getRecipesByUser(authUser.userId + "::" + authUser.username);
      if (isMounted) {
        setUser(userData);
        setRecipes(recipeData);
      }

      //instead we need to search the UserFamily for a list of all families they have
      const familyData = await getUserFamilyByUserId(authUser.userId);
      const currentFamilyList = [];
      for (let i = 0; i < familyData.length; i++) {
        const family = await getFamilyById(familyData[i].familyID);
        currentFamilyList.push(family);
      }

      if (isMounted) {
        setFamilyList(currentFamilyList);
      }

      setLoading(false);
    }
    fetchData();

    return () => {
      isMounted = false;
    };

  }, []); // Empty dependency array


  useEffect(() => {
    if(localStorage.getItem('firstSignIn') === 'true' || localStorage.getItem('firstSignIn') === null)
    {
      console.log('first sign in');
      setTimeout(() => {
        console.log("startiong tutorial now")
        setTutorial(true);
      }, 2000); // Delay of 2 seconds
      localStorage.setItem('firstSignIn', 'false');
    }
  }, []);


  useEffect(() => {
    if (tab !== undefined) {
      setTabValue(tab);
    }
    else {
      setTabValue(0);
    }
  }, [tab]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Stop the tutorial...
      console.log("stopping tutorial")
      
      setTutorial(false);
    }
  };


  if (loading) {
    return <CircularProgress />;
  }

  return (
    
    <>
    <ReactJoyride
      callback={handleJoyrideCallback}
      run={tutorial}
      steps={steps}
    />
      <Meta title="Recipe Roots - Dashboard" />
      <Section size="appBar">
      <UserPublicSection user={user} public={false}  />
      <Container>
        <Section size="auto" style={{marginTop:'16px'}}>
          <div style ={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginBottom: '8px'}}>
          <InviteLinkDialog buttonText="Invite Link" />
          </div>
        
          <Tabs value={tabValue} onChange={handleChange} aria-label="user tabs" centered>
            <Tab label="Recipes" />
            <Tab label="Families" />
            <Tab label="Likes" />
          </Tabs>
          {tabValue === 0 && <>
            {recipes?.length > 0 ?
              <RecipeList recipes={recipes} user={user} userRecipesOnly={true} size="auto" />
              :
              <ErrorSection title="No Recipes Yet!" subtitle="You have not created any recipes yet. Click the button above to create your first recipe!" />
            }
          </>
          }
          {tabValue === 1 && <>
            {familyList?.length > 0 ?
              <FamilyList families={familyList} user={user} />
              :
              <ErrorSection title="No Families Yet!" subtitle="You are not a member of any families yet. Create or join a family to get started!" />
            }
          </>
          }
          {tabValue === 2 && 
            <RecipeLikesUser user={user} />
          }
        </Section>
      </Container>
      </Section>
      
      </>
  );
}

export default withAuthenticator(DashboardPage);
