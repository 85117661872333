import React, { useState } from 'react';
import { Menu, MenuItem, Button, IconButton, Box, Badge, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import KoFiLink from './KoFiLink';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InviteLinkDialog from './InviteLinkDialog';
import EmailIcon from '@mui/icons-material/Email';
import PeopleIcon from '@mui/icons-material/People';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';

function UserCreateSection(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px', width:'350px' }}>
        <KoFiLink  user={props.user} editable={true} />
        <Badge badgeContent={props.invites.length} color="primary">
        <Button className='ActionItems' onClick={handleClick} variant="contained" color="secondary">
          <Box display="flex" alignItems="center" gap={1}>
            Actions
            <Divider orientation="vertical" color="white" flexItem />
            <AddCircleIcon />
          </Box>
        </Button>
        </Badge>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} component={Link} to={"/createRecipe"}><DinnerDiningIcon style={{ marginRight: '8px' }} />Create a Recipe</MenuItem>
          <MenuItem onClick={handleClose} component={Link} to={"/familyCreate"}><PeopleIcon style={{ marginRight: '8px' }} /> Create a Family</MenuItem>
          <Badge badgeContent={props.invites.length} color="primary">
            <MenuItem onClick={handleClose} component={Link} to={"/familyInvites"}> <EmailIcon style={{ marginRight: '8px' }} /> Invites</MenuItem>
          </Badge>
        </Menu>
        <IconButton className='EditButton' component={Link} to={"/userEdit"}>
          <Edit />
        </IconButton>
      </Box >
    );
}

export default UserCreateSection;