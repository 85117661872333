import React from "react";
import Meta from "./../components/Meta";
import CtaSection from "./../components/CtaSection";
import RecipeStreamList from "../components/RecipeStreamList";
import Section from "../components/Section";
import TutorialSlider from "../components/TutorialSlider";
import { styled } from '@mui/system';

const StyledSection = styled(Section)(({ theme }) => ({
  marginTop: '20px',
  marginBottom: '20px',
}));

function IndexPage(props) {
  // const [divHeight, setDivHeight] = useState('');

  // useEffect(() => {
  //   const handleResize = () => {
  //     setDivHeight(`${window.innerWidth * 0.036}vh`); // adjust as needed
  //   };

  //   window.addEventListener('resize', handleResize);
  //   handleResize();

  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  return (
    <>
      <Meta />
      <StyledSection size="appBar">
        <TutorialSlider />
        <RecipeStreamList size="medium" title="Recently Added" tag="" />
        <RecipeStreamList size="medium" title="Italian" tag="Italian" />
        <RecipeStreamList size="medium" title="Indonesian" tag="Indonesian" />
        {/* <RecipeStreamList size="medium" title="Irish" tag="Irish" />
        <RecipeStreamList size="medium" title="Puerto Rican" tag="Puerto_Rican" /> */}
        <CtaSection
          bgColor="primary"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
          title="Ready to get creating?"
          subtitle=""
          buttonText="Get Started"
          // buttonColor="default"
          buttonPath="/createRecipe"
        />
      </StyledSection>
    </>
  );
}

export default IndexPage;
