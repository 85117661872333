/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFamilyInvite = /* GraphQL */ `
  mutation CreateFamilyInvite(
    $input: CreateFamilyInviteInput!
    $condition: ModelFamilyInviteConditionInput
  ) {
    createFamilyInvite(input: $input, condition: $condition) {
      id
      creator
      invitee
      familyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFamilyInvite = /* GraphQL */ `
  mutation UpdateFamilyInvite(
    $input: UpdateFamilyInviteInput!
    $condition: ModelFamilyInviteConditionInput
  ) {
    updateFamilyInvite(input: $input, condition: $condition) {
      id
      creator
      invitee
      familyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFamilyInvite = /* GraphQL */ `
  mutation DeleteFamilyInvite(
    $input: DeleteFamilyInviteInput!
    $condition: ModelFamilyInviteConditionInput
  ) {
    deleteFamilyInvite(input: $input, condition: $condition) {
      id
      creator
      invitee
      familyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFamily = /* GraphQL */ `
  mutation CreateFamily(
    $input: CreateFamilyInput!
    $condition: ModelFamilyConditionInput
  ) {
    createFamily(input: $input, condition: $condition) {
      id
      name
      about
      origins
      members {
        nextToken
        __typename
      }
      Recipes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFamily = /* GraphQL */ `
  mutation UpdateFamily(
    $input: UpdateFamilyInput!
    $condition: ModelFamilyConditionInput
  ) {
    updateFamily(input: $input, condition: $condition) {
      id
      name
      about
      origins
      members {
        nextToken
        __typename
      }
      Recipes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFamily = /* GraphQL */ `
  mutation DeleteFamily(
    $input: DeleteFamilyInput!
    $condition: ModelFamilyConditionInput
  ) {
    deleteFamily(input: $input, condition: $condition) {
      id
      name
      about
      origins
      members {
        nextToken
        __typename
      }
      Recipes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUserFamily = /* GraphQL */ `
  mutation CreateUserFamily(
    $input: CreateUserFamilyInput!
    $condition: ModelUserFamilyConditionInput
  ) {
    createUserFamily(input: $input, condition: $condition) {
      id
      userID
      familyID
      user {
        id
        username
        about
        image
        kofiLink
        name
        email
        createdAt
        updatedAt
        owner
        __typename
      }
      family {
        id
        name
        about
        origins
        createdAt
        updatedAt
        owner
        __typename
      }
      familyOwner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserFamily = /* GraphQL */ `
  mutation UpdateUserFamily(
    $input: UpdateUserFamilyInput!
    $condition: ModelUserFamilyConditionInput
  ) {
    updateUserFamily(input: $input, condition: $condition) {
      id
      userID
      familyID
      user {
        id
        username
        about
        image
        kofiLink
        name
        email
        createdAt
        updatedAt
        owner
        __typename
      }
      family {
        id
        name
        about
        origins
        createdAt
        updatedAt
        owner
        __typename
      }
      familyOwner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserFamily = /* GraphQL */ `
  mutation DeleteUserFamily(
    $input: DeleteUserFamilyInput!
    $condition: ModelUserFamilyConditionInput
  ) {
    deleteUserFamily(input: $input, condition: $condition) {
      id
      userID
      familyID
      user {
        id
        username
        about
        image
        kofiLink
        name
        email
        createdAt
        updatedAt
        owner
        __typename
      }
      family {
        id
        name
        about
        origins
        createdAt
        updatedAt
        owner
        __typename
      }
      familyOwner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      about
      Recipes {
        nextToken
        __typename
      }
      Likes {
        nextToken
        __typename
      }
      families {
        nextToken
        __typename
      }
      image
      kofiLink
      name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      about
      Recipes {
        nextToken
        __typename
      }
      Likes {
        nextToken
        __typename
      }
      families {
        nextToken
        __typename
      }
      image
      kofiLink
      name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      about
      Recipes {
        nextToken
        __typename
      }
      Likes {
        nextToken
        __typename
      }
      families {
        nextToken
        __typename
      }
      image
      kofiLink
      name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUserInviteLink = /* GraphQL */ `
  mutation CreateUserInviteLink(
    $input: CreateUserInviteLinkInput!
    $condition: ModelUserInviteLinkConditionInput
  ) {
    createUserInviteLink(input: $input, condition: $condition) {
      id
      userID
      about
      familyIds
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserInviteLink = /* GraphQL */ `
  mutation UpdateUserInviteLink(
    $input: UpdateUserInviteLinkInput!
    $condition: ModelUserInviteLinkConditionInput
  ) {
    updateUserInviteLink(input: $input, condition: $condition) {
      id
      userID
      about
      familyIds
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserInviteLink = /* GraphQL */ `
  mutation DeleteUserInviteLink(
    $input: DeleteUserInviteLinkInput!
    $condition: ModelUserInviteLinkConditionInput
  ) {
    deleteUserInviteLink(input: $input, condition: $condition) {
      id
      userID
      about
      familyIds
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRecipe = /* GraphQL */ `
  mutation CreateRecipe(
    $input: CreateRecipeInput!
    $condition: ModelRecipeConditionInput
  ) {
    createRecipe(input: $input, condition: $condition) {
      id
      title
      about
      tags
      ingredients
      instructions
      userID
      familyID
      Likes {
        nextToken
        __typename
      }
      likeCount
      cookTime
      prepTime
      servings
      image
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRecipe = /* GraphQL */ `
  mutation UpdateRecipe(
    $input: UpdateRecipeInput!
    $condition: ModelRecipeConditionInput
  ) {
    updateRecipe(input: $input, condition: $condition) {
      id
      title
      about
      tags
      ingredients
      instructions
      userID
      familyID
      Likes {
        nextToken
        __typename
      }
      likeCount
      cookTime
      prepTime
      servings
      image
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRecipe = /* GraphQL */ `
  mutation DeleteRecipe(
    $input: DeleteRecipeInput!
    $condition: ModelRecipeConditionInput
  ) {
    deleteRecipe(input: $input, condition: $condition) {
      id
      title
      about
      tags
      ingredients
      instructions
      userID
      familyID
      Likes {
        nextToken
        __typename
      }
      likeCount
      cookTime
      prepTime
      servings
      image
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      userID
      recipeID
      user {
        id
        username
        about
        image
        kofiLink
        name
        email
        createdAt
        updatedAt
        owner
        __typename
      }
      recipe {
        id
        title
        about
        tags
        ingredients
        instructions
        userID
        familyID
        likeCount
        cookTime
        prepTime
        servings
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      userID
      recipeID
      user {
        id
        username
        about
        image
        kofiLink
        name
        email
        createdAt
        updatedAt
        owner
        __typename
      }
      recipe {
        id
        title
        about
        tags
        ingredients
        instructions
        userID
        familyID
        likeCount
        cookTime
        prepTime
        servings
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      userID
      recipeID
      user {
        id
        username
        about
        image
        kofiLink
        name
        email
        createdAt
        updatedAt
        owner
        __typename
      }
      recipe {
        id
        title
        about
        tags
        ingredients
        instructions
        userID
        familyID
        likeCount
        cookTime
        prepTime
        servings
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      email
      feedback
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      email
      feedback
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      email
      feedback
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
