import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { useLocation } from 'react-router-dom';
import { Grid, TextField, Typography, IconButton, Button, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Alert, Box, CircularProgress } from "@mui/material";
import Section from "./Section";
import { generateClient } from "aws-amplify/api";
import DeleteIcon from '@mui/icons-material/Delete';
import { createFamilyInvite, deleteFamilyInvite, deleteUserFamily } from "../graphql/mutations";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { getCurrentUser } from 'aws-amplify/auth';

import { getFamilyById, getFamilyMembers, getUser, searchUsersByUsername, getFamilyInvitesByFamilyID, getUserFamilyByUserId } from "../util/api";
import { styled } from "@mui/system";

const StyledGrid = styled(Grid)(({ theme }) => ({
    textAlign: "center",
    flexDirection: "row",
}));


const TextInput = styled(TextField)({
    width: "40%",
    minWidth: "225px",
});


function FamilyAddMembersForm(props) {
    const client = generateClient();
    const [family, setFamily] = useState({});
    const [user, setUser] = useState({});
    const [members, setMembers] = useState([]);
    const [inviteMembers, setInviteMembers] = useState([]);
    const [userList, setUserList] = useState([]);
    const [memberSearch, setMemberSearch] = useState("");
    const { search } = useLocation();
    const [invitedMembers, setInvitedMembers] = useState([]);
    const [userFound, setUserFound] = useState(true);
    const [inviteHelperText, setInviteHelperText] = useState("");
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState("");
    const [loading, setLoading] = useState(false);
    // Access query parameters
    const query = new URLSearchParams(search);
    const id = query.get('id');

    useEffect(() => {
        //integreate all members and invites into one list
        const mergeMembersAndInvites = async (members, invites, family) => {
            var allMembers = members;
            for (var i = 0; i < allMembers.length; i++) {
                allMembers[i].status = "Member";
                if (allMembers[i].username === family.owner) {
                    allMembers[i].status = "Owner";
                }
            }
            for (var i = 0; i < invites.length; i++) {
                var user = await getUser(invites[i].invitee);
                if (user) {
                    user.status = "Invited";
                    allMembers.push(user);
                }
            }
            setUserList(allMembers);
        }

        async function fetchData() {
            setLoading(true);
            const authUser = await getCurrentUser();
            const currentUser = await getUser(authUser.userId);
            setUser(currentUser);
            const family = await getFamilyById(id);
            setFamily(family);
            const members = await getFamilyMembers(id);
            setMembers(members);
            const invites = await getFamilyInvitesByFamilyID(id);
            setInviteMembers(invites);
            await mergeMembersAndInvites(members, invites, family);
            setLoading(false);
        }
        fetchData();
    }, []);

    async function createInvite() {
        var invitedUser = {};
        //get the user id of the user to be invited
        try {
            const user = await searchUsersByUsername(memberSearch);
            if (user.length === 0) {
                setUserFound(false);
                setAlert("User not found");
                setError(true);
                return;
            }
            //WE found the user
            else {
                invitedUser = user[0];

                setInviteHelperText("");
                //check if the user is already a member of the family
                for (var i = 0; i < members.length; i++) {
                    if (members[i].id === invitedUser.id) {
                        setInviteHelperText("User is already invited or a member of the family");
                        setError(true);
                        setUserFound(false);
                        return;
                    }
                }
                //check if the user is already invited
                for (var i = 0; i < inviteMembers.length; i++) {
                    if (inviteMembers[i].invitee === invitedUser.id) {
                        setInviteHelperText("User is already invited or a member to the family");
                        setUserFound(false);
                        return;
                    }
                }
            }
        }
        catch (error) {
            setAlert("Error searching for user. Please try again.");
            setError(true);
            console.log("Error searching for user:", error);
        }

        try {
            const authUser = await getCurrentUser();
            const newInvite = {
                familyID: family.id,
                creator: authUser.userId,
                invitee: invitedUser.id,
            }
            await client.graphql({ query: createFamilyInvite, variables: { input: newInvite } })
                .then((response) => {
                    console.log(response);
                    //merge lists of members and invites
                    invitedUser.status = "Invited";
                    setInvitedMembers(inviteMembers.push(invitedUser));
                    setAlert("Invite sent!");
                    setError(false);
                })
                .catch((error) => console.log(error));
        }
        catch (error) {
            console.log("error creating invite", error);
        }
        setAlert("Invite sent! - " + memberSearch);
    }

    const deleteInvite = async (member) => {
        //prompt user to see if they want to delete the invite
        if(!window.confirm("Are you sure you want to delete this invite?")){
            return;
        }

        let id = '';
        //get the invite
        for (var i = 0; i < inviteMembers.length; i++) {
            if (inviteMembers[i].invitee === member.id) {
                console.log(inviteMembers[i])
                id = inviteMembers[i].id;
            }
        }
        if (id === '') {
            console.log("Invite not found");
            return;
        }
        try {
            await client.graphql({ query: deleteFamilyInvite, variables: { input: { id: id } } })
                .then((response) => {
                    setUserList(userList.filter(invite => invite.id !== member.id));
                    console.log(inviteMembers)
                    setError(false);
                    setAlert("Invite has been removed!");
                })
                .catch((error) => {
                    console.log(error);
                    setAlert("Error removing invite. Please try again.")
                    setError(true);
                });
        }
        catch (error) {
            console.log("error deleting invite", error);
        }
    }

    const deleteMember = async (member) => {
        //delete the member
        if(!window.confirm("Are you sure you want to remove this Member?")){
            return;
        }
        setLoading(true);
        try {
            const userFamilyList = await getUserFamilyByUserId(member.id);
            if(userFamilyList.length === 0){
                throw new Error("User family not found");
            }
            let userFamily;
            //find the userFamily that has familyID 
            userFamilyList.forEach((familyRelationship) => {
                if(familyRelationship.familyID === family.id){
                    userFamily = familyRelationship;
                }
            });
            if(userFamilyList.length === 0 || userFamily === undefined){
                throw new Error("User family not found");
            }



            await client.graphql({ query: deleteUserFamily, variables: { input: { id: userFamily.id } } });
            setUserList(userList.filter(user => user.id !== member.id));
            setLoading(false);
            setError(false);
            setAlert("User has been removed from the family!");
            
        }
        catch (error) {
            setLoading(false);
            console.log("error deleting member", error);
            setAlert("Error removing user from family. Please try again.")
            setError(true);
        }
    }


    if (loading) {
        return (
            <CircularProgress />
        )
    }

    return (
        <>
            <Section bgColor={props.bgColor}>
                <Container>
                    <StyledGrid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h4">Invite Users to Your Family</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <TextInput
                                    label="Invite User"
                                    variant="filled"
                                    type="text"
                                    name="memberSearch"
                                    placeholder="username"
                                    helperText="This is case senstive"
                                    value={memberSearch}
                                    onChange={(event) => { setMemberSearch(event.target.value); setAlert(''); setError(false); setUserFound(false); }} />
                                <Box ml={2}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={() => { createInvite() }}
                                    >
                                        Invite
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        {alert && !error &&
                            <Grid item xs={12}>
                                <Alert severity="success">{alert}</Alert>
                            </Grid>
                        }
                        {alert && error &&
                            <Grid item xs={12}>
                                <Alert severity="error">{alert}</Alert>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>UserName</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userList.map((member, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{member.username}</TableCell>
                                                <TableCell>{member.status}</TableCell>
                                                {member.status === "Member" && <TableCell><IconButton onClick={() => deleteMember(member)}><DeleteIcon /></IconButton></TableCell>}
                                                {member.status ===  "Invited" && <TableCell><IconButton onClick={() => deleteInvite(member)}><DeleteIcon /></IconButton></TableCell>}
                                                </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </StyledGrid>
                </Container>
            </Section>
        </>
    );
}

export default withAuthenticator(FamilyAddMembersForm);
