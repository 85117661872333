export const getFamilyOriginsDisplayValues = {
      IRISH: "Irish",
      ITALIAN: "Italian",
      INDONESIAN: "Indonesian",
      GERMAN: "German",
      POLISH: "Polish",
      FRENCH: "French",
      ENGLISH: "English",
      SCOTTISH: "Scottish",
      WELSH: "Welsh",
      DUTCH: "Dutch",
      SWEDISH: "Swedish",
      NORWEGIAN: "Norwegian",
      DANISH: "Danish",
      FINNISH: "Finnish",
      RUSSIAN: "Russian",
      GREEK: "Greek",
      SPANISH: "Spanish",
      PORTUGUESE: "Portuguese",
      MEXICAN: "Mexican",
      CUBAN: "Cuban",
      PUERTO_RICAN: "Puerto rican",
      DOMINICAN: "Dominican",
      COLUMBIAN: "Columbian",
      VENEZUELAN: "Venezuelan",
      BRAZILIAN: "Brazilian",
      ARGENTINIAN: "Argentinian",
      PERUVIAN: "Peruvian",
      ECUADORIAN: "Ecuadorian",
      CHILEAN: "Chilean",
      BOLIVIAN: "Bolivian",
      PARAGUAYAN: "Paraguayan",
      URUGUAYAN: "Uruguayan",
      AUSTRIAN: "Austrian",
      SWISS: "Swiss",
      BELGIAN: "Belgian",
      HUNGARIAN: "Hungarian",
      CZECH: "Czech",
      SLOVAK: "Slovak",
      ROMANIAN: "Romanian",
      BULGARIAN: "Bulgarian",
      TURKISH: "Turkish",
      MOROCCAN: "Moroccan",
      EGYPTIAN: "Egyptian",
      TUNISIAN: "Tunisian",
      ALGERIAN: "Algerian",
      NIGERIAN: "Nigerian",
      ETHIOPIAN: "Ethiopian",
      KENYAN: "Kenyan",
      SOUTH_AFRICAN: "South african",
      INDIAN: "Indian",
      PAKISTANI: "Pakistani",
      BANGLADESHI: "Bangladeshi",
      SRI_LANKAN: "Sri lankan",
      NEPALESE: "Nepalese",
      THAI: "Thai",
      VIETNAMESE: "Vietnamese",
      CHINESE: "Chinese",
      JAPANESE: "Japanese",
      KOREAN: "Korean",
      FILIPINO: "Filipino",
      AUSTRALIAN: "Australian",
      NEW_ZEALANDER: "New zealander",
      CANADIAN: "Canadian",
      AMERICAN: "American",
      HAWAIIAN: "Hawaiian",
      ALASKAN: "Alaskan",
    };

export const familyOriginsTags = ["IRISH", "ITALIAN", "INDONESIAN", "GERMAN", "POLISH", "FRENCH", "ENGLISH", "SCOTTISH", "WELSH", "DUTCH", "SWEDISH", "NORWEGIAN", "DANISH", "FINNISH", "RUSSIAN", "GREEK", "SPANISH", "PORTUGUESE", "MEXICAN", "CUBAN", "PUERTO_RICAN", "DOMINICAN", "COLUMBIAN", "VENEZUELAN", "BRAZILIAN", "ARGENTINIAN", "PERUVIAN", "ECUADORIAN", "CHILEAN", "BOLIVIAN", "PARAGUAYAN", "URUGUAYAN", "AUSTRIAN", "SWISS", "BELGIAN", "HUNGARIAN", "CZECH", "SLOVAK", "ROMANIAN", "BULGARIAN", "TURKISH", "MOROCCAN", "EGYPTIAN", "TUNISIAN", "ALGERIAN", "NIGERIAN", "ETHIOPIAN", "KENYAN", "SOUTH_AFRICAN", "INDIAN", "PAKISTANI", "BANGLADESHI", "SRI_LANKAN", "NEPALESE", "THAI", "VIETNAMESE", "CHINESE", "JAPANESE", "KOREAN", "FILIPINO", "AUSTRALIAN", "NEW_ZEALANDER", "CANADIAN", "AMERICAN", "HAWAIIAN", "ALASKAN"];

export const getRecipeTagsDisplayValue = {
      BREAKFAST: "Breakfast",
      BRUNCH: "Brunch",
      LUNCH: "Lunch",
      APPETIZER: "Appetizer",
      SIDE: "Side",
      DINNER: "Dinner",
      DESSERT: "Dessert",
      CLASSIC: "Classic",
      EASY: "Easy",
      QUICK: "Quick",
      COMFORT: "Comfort",
      TRADITIONAL: "Traditional",
      HOLIDAY: "Holiday",
      SNACK: "Snack",
      CELEBRATION: "Celebration",
      PARTY: "Party",
      SIMPLE: "Simple",

};
export const recipeTags = ["DESSERT", "SWEET", "COOKIES", "CLASSIC", "EASY", "QUICK", "COMFORT", "TRADITIONAL", "HOLIDAY", "SNACK", "CELEBRATION", "PARTY", "SIMPLE", "DINNER", "LUNCH", "BRUNCH", "BREAKFAST"];
