import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Edit from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";
import { styled } from '@mui/system';
import stockPhoto from "../images/stockRecipe.jpg";
import { getFamilyById } from "../util/api"
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite'; // Import the filled icon for the Like button
import { toggleLikeOnRecipe } from '../util/common';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

const CardStyled = styled(Card)(({ theme }) => ({
  width: 235,
  marginBottom: theme.spacing(2),
  //round out edges
  borderRadius: 10,
  padding: 0,
  border: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
  },
}));

const CardMediaStyled = styled(CardMedia)({
  height: 140,
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, transparent 80%, white)',
  },
});

const CardContentStyled = styled(CardContent)({
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 0,
  paddingBottom: 2,
  overflow: 'hidden',
});

const CardActionAreaStyled = styled(CardActionArea)({
  padding: 0,
  margin: 0
});

const Title = styled(Typography)({
  // whiteSpace: 'nowrap',
  // overflow: 'hidden',
  // textOverflow: 'ellipsis',
  marginBottom: 1,
});

const LikesBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  borderRadius: 1,
  padding: '.2em',
  paddingRight: '1em',
});



export default function RecipeCard(props) {

  const [family, setFamily] = useState(null);
  const [username, setUsername] = useState(null);
  const [liked, setLiked] = useState(false);
  const [likeId, setLikeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [recipeLikeCount, setRecipeLikeCount] = useState(props.recipe.likeCount);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        setUsername(props.recipe.owner)
        if (props.recipe.familyID !== 0) {
          const familyData = await getFamilyById(props.recipe.familyID);
          setFamily(familyData);
        }
        if (!props.user) return;
        for (let i = 0; i < props.userLikes.length; i++) {
          if (props.userLikes[i].recipeID === props.recipe.id) {
            setLiked(true);
            setLikeId(props.userLikes[i].id);
          }
        }

      } catch (error) {
        console.log('error getting current user: ', error);
        setLoading(false);
      }
      setLoading(false);
    };
    fetchData();
    setLoading(false);
  }, []);



  const setLikeOnRecipe = async () => {
    try {
      const newLikeId = await toggleLikeOnRecipe(liked, likeId, props.recipe.id, props.user);
      if (newLikeId === 0) {
        setLiked(false);
        setRecipeLikeCount(recipeLikeCount - 1)
        return;
      }
      setLiked(true);
      setLikeId(newLikeId);
      setRecipeLikeCount(recipeLikeCount + 1)

    } catch (error) {
      console.log('error setting like on recipe: ', error);
    }
  }

  if (loading) return (<CardStyled> <CardContentStyled><CircularProgress /></CardContentStyled></CardStyled  >);

  return (
    <CardStyled>
      <Box position="relative" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <CardActionAreaStyled onClick={() => window.location.href = "/recipe?id=" + props.recipe.id}>
          <CardMediaStyled
            image={props.recipe.imageUrl ? props.recipe.imageUrl : stockPhoto}
            title={props.recipe.title}
          />
          <CardContentStyled>
            <Title gutterBottom variant="h6" component="h6" >
              {props.recipe.title}
            </Title>
            <Typography variant="body2" color="textSecondary" component="p">
              {family ? family.name : username}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {props.recipe.tags.slice(0, 1).map((tag, index) => (
                <Chip key={index} label={tag} size="small" style={{ margin: 2 }} />
              ))}
              {props.recipe.tags.length > 1 &&
                <Typography variant="body2" color="textSecondary" component="p">
                  + {props.recipe.tags.length - 1} more
                </Typography>
              }
            </div>

          </CardContentStyled>
        </CardActionAreaStyled>
        {props.userRecipesOnly &&
          <CardActions>
            <IconButton size="small" component={Link} to={"/recipeEdit?recipeId=" + props.recipe.id}>
              <Edit />
            </IconButton>
          </CardActions>
        }
        {hover && (
          <div style={{ position: 'absolute', top: 0, left: 0 }}>
            <LikesBox>
              <IconButton
                size="small"
                onClick={() => setLikeOnRecipe()}
              >
                {liked ? <FavoriteIcon color="secondary" /> : <FavoriteBorderIcon color="secondary" />}
              </IconButton>
              <Typography variant="subtitle1" color="textSecondary">
                {recipeLikeCount}
              </Typography>
            </LikesBox>
          </div>
        )}
      </Box>
    </CardStyled>
  );
}