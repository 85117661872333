import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { getUserFamilyByUserId, getUser, getFamilyById } from "../util/api";
import { getCurrentUser } from 'aws-amplify/auth';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { generateClient } from "aws-amplify/api";
import { FormControlLabel, Checkbox, FormGroup, CircularProgress } from '@mui/material';
import { createUserInviteLink } from "../graphql/mutations";
import AddLinkIcon from '@mui/icons-material/AddLink';

const StyledButton = styled(Button)(({ theme }) => ({
    // position: 'fixed',
    // right: 5,
    // top: 70,
    // margin: '10px',
    borderRadius: 15,
}));

function InviteLinkDialog(props) {
    const [open, setOpen] = useState(false);
    const [snackBar, setSnackBar] = useState(false);
    const [inviteLink, setInviteLink] = useState('');
    const [user, setUser] = useState(null); // [user1, user2, user3, ...
    const [about, setAbout] = useState('Come join me on Recipe Roots! A great place to share and discover home-made recipes!');
    const [familyList, setFamilyList] = useState([]); // [family1, family2, family3, ...]
    const [selectedFamilies, setSelectedFamilies] = useState([]); // [family1, family2, family3, ...
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setInviteLink('');
        setSelectedFamilies([]);
        setAbout('Come join me on Recipe Roots! A great place to share and discover home-made recipes!');
        setSnackBar(false);
    };


    const handleCopyClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
      
        setSnackBar(false);
      };

    const handleCheckboxChange = (event, family) => {
        if (event.target.checked) {
            setSelectedFamilies(prev => [...prev, family]);
        } else {
            setSelectedFamilies(prev => prev.filter(f => f.id !== family.id));
        }
    };

    const createLink = async () => {
        const client = generateClient();
        let listOfFamilyIDs = [];
        for (let i = 0; i < selectedFamilies.length; i++) {
            listOfFamilyIDs.push(selectedFamilies[i].id);
        }

        const invite = {
            about: about,
            familyIds: listOfFamilyIDs,
            userID: user.id
        }
        try {
            const inviteLink = await client.graphql({
                query: createUserInviteLink,
                variables: {
                    input: { ...invite }
                },
            });
            setInviteLink("www.reciperoots.net/invite?inviteID=" + inviteLink.data.createUserInviteLink.id);
            setStep(1);
        }
        catch (error) {
            console.log(error);
        }

    };

    const handleCopy = () => {
        navigator.clipboard.writeText(inviteLink);
        setSnackBar(true);
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const authUser = await getCurrentUser();
                const user = await getUser(authUser.userId);
                setUser(user);
                const familyData = await getUserFamilyByUserId(user.id);

                for (let i = 0; i < familyData.length; i++) {
                    const family = await getFamilyById(familyData[i].familyID);
                    if (family.owner === user.username) {
                        familyData.splice(i, 1);
                    }
                    familyData[i] = family;
                }

                setFamilyList(familyData);
                setLoading(false);
            }
            catch (error) {
                console.log(error);
                setLoading(false);
            }
        }

        fetchData();
    }, []);



    return (
        <div>
            <StyledButton variant="contained" color="secondary" className="linkButton" onClick={handleClickOpen}>
                <Box display="flex" alignItems="center" gap={1}>
                    {props.buttonText} <AddLinkIcon />
                </Box>
            </StyledButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create Your Invite Link</DialogTitle>
                <DialogContent>
                    {loading && step === 0 && inviteLink === '' ?
                        <CircularProgress />
                        :
                        <>
                            <Typography variant="body1" gutterBottom>
                                Send invitations to join your family! Or simply tell them to get started!
                            </Typography>
                            <FormGroup
                            >
                                {familyList.map((family) => {
                                    return (
                                        <FormControlLabel
                                            disabled={inviteLink === '' ? false : true}
                                            key={family.id}
                                            value={family.id}
                                            control={
                                                <Checkbox
                                                    checked={selectedFamilies.some(f => f.id === family.id)}
                                                    onChange={(event) => handleCheckboxChange(event, family)}
                                                />
                                            }
                                            label={family.name}
                                        />
                                    )
                                })}
                            </FormGroup>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="inviteLink"
                                label="Message"
                                type="text"
                                multiline
                                fullWidth
                                value={about}
                                disabled={inviteLink === '' ? false : true}
                                onChange={(event) => setAbout(event.target.value)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                                <Button onClick={() => createLink()} variant="contained" disabled={inviteLink === '' ? false : true}>Create Link</Button>
                            </div>
                        </>
                    }
                    {!loading && step === 1 && inviteLink !== '' &&
                        <>
                            <Typography variant="body1" gutterBottom>Please copy and share this link via text, email, Facebook or any other platform! This link will expire in 7 days.</Typography>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>


                                <TextField value={inviteLink} fullWidth InputProps={{
                                    readOnly: true,
                                    style: { backgroundColor: 'white' }
                                }} />

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCopy}
                                >
                                    Copy Link
                                </Button>
                                <Snackbar
                                    anchorOrigin={{ vertical: 'bottom', horizontal:'center' }}
                                    open={snackBar}
                                    autoHideDuration={6000}
                                    onClose={handleCopyClose}
                                    message="Link copied to clipboard"
                                />
                            </div>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default InviteLinkDialog;
