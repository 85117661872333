import React, { useEffect, useState } from "react";
import Meta from "../components/Meta";
import FilterDrawer from "../components/FilterDrawer";
import RecipeList from "../components/RecipeList";
import FamilyList from "../components/FamilyList";
import UserList from "../components/UserList";
import CircularProgress from "@mui/material/CircularProgress";
import { searchRecipes, searchFamily, getFamilyById, searchUsers, getUser } from "../util/api";
import { useLocation } from 'react-router-dom';
import ErrorSection from "../components/ErrorSection";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { getCurrentUser } from "aws-amplify/auth";
import Section from "../components/Section";
import { Divider } from "@mui/material";

function GeneralSearchPage(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQueryInUrl = queryParams.get('searchQuery');

  const [searchQuery, setSearchQuery] = useState(searchQueryInUrl);
  const [mealOrigins, setMealOrigins] = useState([]);
  const [mealTags, setMealTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recipeLoading, setRecipeLoading] = useState(false);
  const [recipes, setRecipes] = useState([]);
  const [families, setFamilies] = useState([]);
  const [originalRecipes, setOriginalRecipes] = useState([]);
  const [originalFamilies, setOriginalFamilies] = useState([]);
  const [users, setUsers] = useState([]);
  const [value, setValue] = React.useState('1');
  const [user, setUser] = useState(null);


  //useEffect to get user
  useEffect(() => {
    const fetchUser = async () => {
      try{
        const authUser = await getCurrentUser();
        const user = await getUser(authUser.userId);
        setUser(user);
      }
      catch (error) {
        setUser(null);
        console.log('No User: ', error);
      }
    };
    fetchUser();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const searchQueryInUrl = queryParams.get('searchQuery');
      setLoading(true);
      setRecipeLoading(true);
      setSearchQuery(searchQueryInUrl);
      const recipes = await searchRecipes(searchQueryInUrl, [], 50); // Use searchQueryInUrl here
      const originalRecipes = recipes;
      setRecipes(recipes);
      setOriginalRecipes(recipes);
      const families = await searchFamily(searchQueryInUrl, [], 50); // Use searchQueryInUrl here
      setFamilies(families);
      setOriginalFamilies(families);
      const originalFamilies = families
      const users = await searchUsers(searchQueryInUrl, 10);
      setUsers(users);
      if(originalFamilies.length === 0 && originalRecipes.length === 0)
      {
        setValue('3');
      }
      else if(originalRecipes.length === 0 && users.length === 0)
      {
        setValue('2');
      }
      else if(originalRecipes.length === 0)
      {
        setValue('2');
      }
      else
      {
        setValue('1');
      }
      
      
      setRecipeLoading(false);
      setLoading(false);
    };

    fetchData();
  }, [searchQueryInUrl]);


  //useEffect for mealTags on recipes
  useEffect(() => {
    const fetchData = async () => {
      setRecipeLoading(true);
      let filteredRecipes = [...originalRecipes]; // Start with the original recipes
      for (let i = 0; i < filteredRecipes.length; i++) {
        const tags = filteredRecipes[i].tags;
        for (let j = 0; j < mealTags.length; j++) {
          if (!tags.includes(mealTags[j])) {
            filteredRecipes.splice(i, 1);
            i--;
            break;
          }
        }
      }
      if (mealTags.length === 0) {
        filteredRecipes = originalRecipes;
      }
      setRecipes(filteredRecipes);
      setRecipeLoading(false);
    };

    fetchData();
  }, [mealTags]);

  //useEffect for mealOrigins on recipes
  useEffect(() => {
    const fetchData = async () => {
      setRecipeLoading(true);
      let filteredRecipes = [...originalRecipes]; // Start with the original recipes
      for (let i = 0; i < filteredRecipes.length; i++) {
        const family = await getFamilyById(filteredRecipes[i].familyID);
        if(family === null) 
        {
          filteredRecipes.splice(i, 1);
          i--;
          continue;
        }
        const originList = family.origins;
        for (let j = 0; j < mealOrigins.length; j++) {
          if (!originList.includes(mealOrigins[j])) {
            filteredRecipes.splice(i, 1);
            i--;
            break;
          }
        }
      }
      if (mealOrigins.length === 0) {
        filteredRecipes = originalRecipes;
      }
      setRecipes(filteredRecipes);
      setRecipeLoading(false);
    };

    fetchData();
  }, [mealOrigins]);

  const filterProps = { mealOrigins, setMealOrigins, mealTags, setMealTags };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Section size="appBar">
      <Meta title="Recipe Roots - Search" />
  
      {!loading && originalRecipes.length === 0 && families.length === 0 && users.length === 0 && <ErrorSection title="Nothing found!" subtitle="Refine your search criteria and try again" />}
      {loading && <CircularProgress />}
      <Tabs
        value={value}
        onChange={handleChange}
        centered
      >
        {originalRecipes.length > 0 && !loading && <Tab label="Recipes" value="1" />}
        {families.length > 0 && !loading && <Tab label="Families" value="2" />}
        {users.length > 0 && !loading && <Tab label="Users" value="3" />}
      </Tabs>
  
      {value === '1' && <>{!loading &&<FilterDrawer {...filterProps} />}{!recipeLoading ? <RecipeList size="auto" recipes={recipes} user={user}/> : <CircularProgress/>}</>}
      {value === '2' && families.length > 0 && !loading && <FamilyList families={families} />}
      {value === '3' && users.length > 0 && !loading && <UserList users={users} />}
    </Section>
  );
}

export default GeneralSearchPage;