import React from "react";
import Box from "@mui/material/Box";
import { emphasize } from "@mui/system";
import capitalize from "@mui/material/utils/capitalize";
import BackgroundImage from "./BackgroundImage";
import { styled } from "@mui/system";

const SectionBox = styled(Box)(({ theme }) => ({
  position: "relative",
  "& > .MuiContainer-root": {
    position: "relative",
  },
  ...[
    ["default", theme.palette.background.default],
    ["light", emphasize(theme.palette.background.default, 0.03)],
    ["primary", theme.palette.primary.main],
    ["secondary", theme.palette.secondary.main],
  ].reduce((acc, [name, value]) => {
    acc[`&.color${capitalize(name)}`] = {
      backgroundColor: value,
      color: theme.palette.getContrastText(value),
      [`& + &`]: {
        borderTop: `1px solid ${emphasize(value, 0.09)}`,
      },
    };
    return acc;
  }, {}),
  "&.colorInherit": {
    color: "inherit",
  },
  "&.colorTransparent": {
    backgroundColor: "transparent",
    color: "inherit",
  },
}));

function Section(props) {
  const {
    bgColor = "default",
    bgImage,
    bgImageOpacity,
    size = "normal",
    className,
    children,
    ...otherProps
  } = props;

  const verticalPadding = {
    normal: { xs: 6 },
    medium: { xs: 6, sm: 10 },
    large: { xs: 6, sm: 20 },
    appBar: { xs: 8, sm: 9},
    auto: 0,
  }[size];

  return (
    <SectionBox
      component="section"
      py={verticalPadding}
      className={`color${capitalize(bgColor)}${className ? ` ${className}` : ""}`}
      {...otherProps}
    >
      {bgImage && <BackgroundImage image={bgImage} opacity={bgImageOpacity} />}

      {props.children}
    </SectionBox>
  );
}

export default Section;