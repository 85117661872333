import React from "react";
import Meta from "../components/Meta";
import FamilyCreateForm from "../components/FamilyCreateForm";
import { withAuthenticator } from "@aws-amplify/ui-react";
function FamilyCreatePage(props) {

  return (
    <>
    <Meta title="Create a Family"/>
      <FamilyCreateForm size="appBar"/>
    </>
    );
}

export default withAuthenticator(FamilyCreatePage);


