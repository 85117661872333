import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
// import "./App.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import IndexPage from "./pages/index";
import DashboardPage from "./pages/dashboard";
import "@aws-amplify/ui-react/styles.css";
import { ThemeProvider } from "./util/theme";
import RecipeCreatePage from "./pages/recipeCreate";
import FamilyCreatePage from "./pages/familyCreate";
import FamilyAddMembersPage from "./pages/familyAddMembers";
import RecipeViewPage from "./pages/recipeView";
import FamilyViewPage from "./pages/familyView";
import FamilyEditPage from "./pages/familyEdit";
import UserEditPage from "./pages/userEdit";
import UserFamilyInvitesPage from "./pages/userFamilyInvites";
import RecipeEditPage from "./pages/recipeEdit";
import NotFoundPage from "./pages/404";
import InviteLinkPage from "./pages/inviteLink";
import RRLogoWithoutText from "./images/Recipe Roots Without Slogan.png"
import RRLogoSymbol from "./images/Recipe Roots Logo Symbol.png"
import { fetchAuthSession } from "@aws-amplify/core";
import GeneralSearchPage from "./pages/generalSearch";
import UserViewPage from "./pages/userView";

function PrivateRoute({ children, ...rest }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    checkAuthState();
  }, []);

  async function checkAuthState() {
    try {
      await fetchAuthSession();
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    }
    setIsCheckingAuth(false);
  }

  if (isCheckingAuth) {
    return null; // or render a loading spinner
  }
  return isAuthenticated ? children : <Navigate to="/" />;
  
}

function App(props) {
  return (
    
    <ThemeProvider>
      <Router>
        <Navbar
          color="default"
          logo={RRLogoWithoutText}
          logoSymbol={RRLogoSymbol}
        />
        <Routes>
          <Route path="/*" element={<NotFoundPage />} />
          <Route path="/" element={<IndexPage />} />
          <Route path="/generalSearch" element={<GeneralSearchPage />} />
          <Route path="/recipe" element={<RecipeViewPage />} />
          <Route path="/family" element={<FamilyViewPage />} />
          <Route path="/user" element={<UserViewPage />} />
          <Route path="/dashboard/:tab?" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
          <Route path="/createRecipe" element={<PrivateRoute><RecipeCreatePage /></PrivateRoute>} />
          <Route path="/familyCreate" element={<PrivateRoute><FamilyCreatePage /></PrivateRoute>} />
          <Route path="/familyAddMembers" element={<PrivateRoute><FamilyAddMembersPage /></PrivateRoute>} />
          <Route path="/familyEdit" element={<PrivateRoute><FamilyEditPage /></PrivateRoute>} />
          <Route path="/userEdit" element={<PrivateRoute><UserEditPage /></PrivateRoute>} />
          <Route path="/recipeEdit" element={<PrivateRoute><RecipeEditPage /></PrivateRoute>} />
          <Route path="/familyInvites" element={<PrivateRoute><UserFamilyInvitesPage /></PrivateRoute>} />
          <Route path="/invite" element={<PrivateRoute><InviteLinkPage/></PrivateRoute>} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;