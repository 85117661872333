import React from "react";
import { Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FamilyCard from "./FamilyCard";
import Grow from '@mui/material/Grow';

const StyledSection = styled(Section)(({ theme }) => ({
  marginTop: "20px",
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: "100%",
  justifyContent: "center",
}));

function FamilyList(props) {
  return (
    <StyledSection
      bgColor={props.bgColor}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <StyledGrid container={true} spacing={2}>
            {props?.families?.map((family, index) => (
              <Grow in={true} timeout={500 * index} key={index}>
              <Grid item key={index}>
                <FamilyCard
                family={family}
                user={props.user}/>
              </Grid>
              </Grow>
            ))}
          </StyledGrid>
      </Container>
    </StyledSection>
  );
}

export default FamilyList;