import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import RecipeCard from "./RecipeCard";
import { getCurrentUser } from 'aws-amplify/auth';
import { getLikesByUser } from '../util/api';
import CircularProgress from "@mui/material/CircularProgress";
import Grow from '@mui/material/Grow';
const StyledSection = styled(Section)(({ theme }) => ({
  marginTop: "20px",
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: "100%",
  justifyContent: "center",
}));

function RecipeList(props) {
  const [user, setUser] = useState(null);
  const [userLikes, setUserLikes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserLikes = async () => {
      setLoading(true);
      try {
        if(props.user)
        {
          setUser(props.user);
          const userLikes = await getLikesByUser(props.user.id);
          setUserLikes(userLikes);
          setLoading(false);
        }
        else
        {
          try{
            const user = await getCurrentUser();
            setUser(user);
            if (!user) {
              setUserLikes([]);
              setLoading(false);
              return;
            }
            const userLikes = await getLikesByUser(user.userId);
            setUserLikes(userLikes);
            setLoading(false);
          }
          catch (error) {
            console.log('No user ');
            setUserLikes([]);
            setLoading(false);
          }
          
        }
      }
      catch (error) {
        console.log('error getting current user likes: ', error);
        setUserLikes([]);
        setLoading(false);
      }
    };
    fetchUserLikes();

  }, []);

  if (loading) return (<CircularProgress />);

  return (
    <StyledSection
      bgColor={props.bgColor}
      size={props.size}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <StyledGrid container={true} spacing={2}>
          {props?.recipes?.map((recipe, index) => (
            <Grow in={true} timeout={index * 500} key={index}>
              <Grid item key={index}>
                <RecipeCard
                  recipe={recipe}
                  userRecipesOnly={props.userRecipesOnly}
                  userLikes={userLikes}
                  user={user} />
              </Grid>
            </Grow>
          ))}
        </StyledGrid>
      </Container>
    </StyledSection>
  );
}

export default RecipeList;