import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import KoFiImage from '../images/kofi.png';
import { updateUser } from '../graphql/mutations';
import { generateClient } from "aws-amplify/api";
import { Box } from '@mui/material';

function KoFiLinkSetup(props) {
  const [open, setOpen] = useState(false);
  const [koFiLink, setKoFiLink] = useState(props.user.kofiLink);
  const [ableToSave, setAbleToSave] = useState(false);
  const [step, setStep] = useState(0);
  const [testing, setTesting] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLinkChange = (event) => {
    setKoFiLink(event.target.value);
  };

  const handleDelete = async () => {
    const client = generateClient();
    const input = {
      id: props.user.id,
      kofiLink: ''
    };
    try {
      await client.graphql({ query: updateUser, variables: { input: input } });
      alert("Ko-fi link removed successfully!");
    }
    catch (error) {
      console.log(error);
      alert("Error removing Ko-fi link. Please try again.")
    }

    handleClose();
  };


  const handleSubmit = async () => {
    const client = generateClient();
    const input = {
      id: props.user.id,
      kofiLink: koFiLink
    };
    try {
      if (ableToSave === false) {
        alert("The link is not correct. Please ensure you have the correct link.");
        return;
      }
      await client.graphql({ query: updateUser, variables: { input: input } });
      alert("Ko-fi link updated successfully!");
    }
    catch (error) {
      console.log(error);
      alert("Error updating Ko-fi link. Please try again.")
    }

    handleClose();
  };

  const handleTestLink = () => {
    //check that the first part of the link is correct so that the link starts with https://ko-fi.com/
    if (koFiLink.startsWith('https://ko-fi.com/')) {

      //now ensure that after the last "/" there is a string of characters
      let linkSplit = koFiLink.split("/");
      if (linkSplit[3] === "") {
        alert("The link is not correct. Please ensure you have the correct link.");
        setAbleToSave(false);
        return;
      }

      //if it is, then we can alert the user that the link is correct
      setAbleToSave(true);
      setTesting(true);
    }
    else {
      //if it is not, then we can alert the user that the link is not correct
      alert("The link is not correct. Please ensure your link starts with https://ko-fi.com/");
      setAbleToSave(false);
    }
  };

  if (testing) {
    return (
      <>
        <Dialog open={true} >
          <iframe
            id='kofiframe'
            src={koFiLink + '/?hidefeed=true&widget=true&embed=true&preview=true'}
            style={{ border: 'none', width: '100%', padding: '4px', background: '#f9f9f9' }}
            height='712'
            title='reciperoots'
          />
          <Box display="flex" justifyContent="center" alignItems="center" gap={1} style={{ padding: '12px' }}>
          <Typography variant="body1">Does this look like your ko-fi donation link? If so, please save, or go back and adjust you link.</Typography>
          </Box>
          <DialogActions style={{justifyContent:'space-between'}}>
            <Button onClick={() => setTesting(false)} variant="outlined" color="secondary">
              Adjust Link
            </Button>
            <Button onClick={() => { setTesting(false); handleSubmit() }} variant="contained" color="primary">
              Looks good!
            </Button>

          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <div>
      <Button className='koFiLink' variant="outlined" color="secondary" onClick={handleClickOpen}>
        {props.user.kofiLink !== '' ? 'Adjust Link' : 'Start Earning!'}<img src={KoFiImage} alt="Ko-Fi" style={{ width: '30px', height: '30px' }} />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Set up your Ko-fi Donation Link</DialogTitle>
        {step === 0 && <DialogContent>
          <Typography variant="body1"><a href="https://ko-fi.com/" target="_blank" rel="noopener noreferrer" >Ko-fi </a> is a great way to earn money from your recipes. You can set up a donation link and start earning money from your recipes. Click Next and follow the instructions to begin! <br /> <br />
            Your Ko-fi link will be displayed on your profile and on your recipes.
          </Typography>
          <DialogActions>
            <Button onClick={() => setStep(1)} variant="contained" color="primary">
              Next
            </Button>
          </DialogActions>
        </DialogContent>}
        {step === 1 &&
          <>
            <DialogContent>
              <Typography variant="body1"><span style={{ textDecoration: 'underline' }}>Step 1:</span> Navigate to <a href="https://ko-fi.com/account/Register" target="_blank" rel="noopener noreferrer">Ko-Fi</a> and sign up for an account.</Typography>
              <Typography variant="body1"><span style={{ textDecoration: 'underline' }}>Step 2:</span> Follow the on-screen instructions for setting up your profile how you would like.</Typography>
              <Typography variant="body1"><span style={{ textDecoration: 'underline' }}>Step 3:</span> Ensure you attach a payment method so you can start earning.</Typography>
              <Typography variant="body1"><span style={{ textDecoration: 'underline' }}>Step 4:</span> Go to your homepage, and copy your share link. It should look something like "https://ko-fi.com/yourKoFiName"</Typography>
              <Typography><span style={{ textDecoration: 'underline' }}>Step 5:</span> Paste your Ko-fi link in the input field below.</Typography>
              <TextField
                autoFocus
                margin="dense"
                label="Ko-fi Link"
                type="url"
                fullWidth
                placeholder='https://ko-fi.com/reciperoots'
                value={koFiLink}
                onChange={handleLinkChange}
              />

              <Typography variant="body1"><span style={{ textDecoration: 'underline' }}>Step 6:</span> Test your link, to ensure that you have proper connection. Once Tested, you may save!</Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between' }}>
              <Button onClick={() => setStep(0)} variant="outlined" color="secondary">
                Back
              </Button>
              {props.user.kofiLink !== '' && <Button onClick={handleDelete} variant="outlined" color="secondary">
                Remove Link
              </Button>}
              <Button onClick={handleTestLink} variant="contained" color="primary">
                Test and Save!
              </Button>
            </DialogActions>
          </>
        }
      </Dialog>
    </div>
  );
}

export default KoFiLinkSetup;