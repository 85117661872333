/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFamilyInvite = /* GraphQL */ `
  query GetFamilyInvite($id: ID!) {
    getFamilyInvite(id: $id) {
      id
      creator
      invitee
      familyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFamilyInvites = /* GraphQL */ `
  query ListFamilyInvites(
    $filter: ModelFamilyInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFamilyInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creator
        invitee
        familyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const familyInvitesByCreator = /* GraphQL */ `
  query FamilyInvitesByCreator(
    $creator: String!
    $sortDirection: ModelSortDirection
    $filter: ModelFamilyInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    familyInvitesByCreator(
      creator: $creator
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        invitee
        familyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const familyInvitesByInvitee = /* GraphQL */ `
  query FamilyInvitesByInvitee(
    $invitee: String!
    $sortDirection: ModelSortDirection
    $filter: ModelFamilyInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    familyInvitesByInvitee(
      invitee: $invitee
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        invitee
        familyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const familyInvitesByFamilyID = /* GraphQL */ `
  query FamilyInvitesByFamilyID(
    $familyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFamilyInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    familyInvitesByFamilyID(
      familyID: $familyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        invitee
        familyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFamily = /* GraphQL */ `
  query GetFamily($id: ID!) {
    getFamily(id: $id) {
      id
      name
      about
      origins
      members {
        nextToken
        __typename
      }
      Recipes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFamilies = /* GraphQL */ `
  query ListFamilies(
    $filter: ModelFamilyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFamilies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        about
        origins
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchFamilies = /* GraphQL */ `
  query SearchFamilies(
    $filter: SearchableFamilyFilterInput
    $sort: [SearchableFamilySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableFamilyAggregationInput]
  ) {
    searchFamilies(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        about
        origins
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getUserFamily = /* GraphQL */ `
  query GetUserFamily($id: ID!) {
    getUserFamily(id: $id) {
      id
      userID
      familyID
      user {
        id
        username
        about
        image
        kofiLink
        name
        email
        createdAt
        updatedAt
        owner
        __typename
      }
      family {
        id
        name
        about
        origins
        createdAt
        updatedAt
        owner
        __typename
      }
      familyOwner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserFamilies = /* GraphQL */ `
  query ListUserFamilies(
    $filter: ModelUserFamilyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFamilies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        familyID
        familyOwner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userFamiliesByUserID = /* GraphQL */ `
  query UserFamiliesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFamilyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userFamiliesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        familyID
        familyOwner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userFamiliesByFamilyID = /* GraphQL */ `
  query UserFamiliesByFamilyID(
    $familyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFamilyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userFamiliesByFamilyID(
      familyID: $familyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        familyID
        familyOwner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userFamiliesByFamilyOwner = /* GraphQL */ `
  query UserFamiliesByFamilyOwner(
    $familyOwner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFamilyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userFamiliesByFamilyOwner(
      familyOwner: $familyOwner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        familyID
        familyOwner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      about
      Recipes {
        nextToken
        __typename
      }
      Likes {
        nextToken
        __typename
      }
      families {
        nextToken
        __typename
      }
      image
      kofiLink
      name
      email
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        about
        image
        kofiLink
        name
        email
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        username
        about
        image
        kofiLink
        name
        email
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getUserInviteLink = /* GraphQL */ `
  query GetUserInviteLink($id: ID!) {
    getUserInviteLink(id: $id) {
      id
      userID
      about
      familyIds
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserInviteLinks = /* GraphQL */ `
  query ListUserInviteLinks(
    $filter: ModelUserInviteLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInviteLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        about
        familyIds
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userInviteLinksByUserID = /* GraphQL */ `
  query UserInviteLinksByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserInviteLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInviteLinksByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        about
        familyIds
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecipe = /* GraphQL */ `
  query GetRecipe($id: ID!) {
    getRecipe(id: $id) {
      id
      title
      about
      tags
      ingredients
      instructions
      userID
      familyID
      Likes {
        nextToken
        __typename
      }
      likeCount
      cookTime
      prepTime
      servings
      image
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRecipes = /* GraphQL */ `
  query ListRecipes(
    $filter: ModelRecipeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecipes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        about
        tags
        ingredients
        instructions
        userID
        familyID
        likeCount
        cookTime
        prepTime
        servings
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const recipesByUserID = /* GraphQL */ `
  query RecipesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecipeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recipesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        about
        tags
        ingredients
        instructions
        userID
        familyID
        likeCount
        cookTime
        prepTime
        servings
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const recipesByFamilyID = /* GraphQL */ `
  query RecipesByFamilyID(
    $familyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecipeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recipesByFamilyID(
      familyID: $familyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        about
        tags
        ingredients
        instructions
        userID
        familyID
        likeCount
        cookTime
        prepTime
        servings
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchRecipes = /* GraphQL */ `
  query SearchRecipes(
    $filter: SearchableRecipeFilterInput
    $sort: [SearchableRecipeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRecipeAggregationInput]
  ) {
    searchRecipes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        about
        tags
        ingredients
        instructions
        userID
        familyID
        likeCount
        cookTime
        prepTime
        servings
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      userID
      recipeID
      user {
        id
        username
        about
        image
        kofiLink
        name
        email
        createdAt
        updatedAt
        owner
        __typename
      }
      recipe {
        id
        title
        about
        tags
        ingredients
        instructions
        userID
        familyID
        likeCount
        cookTime
        prepTime
        servings
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        recipeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const likesByUserID = /* GraphQL */ `
  query LikesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        recipeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const likesByRecipeID = /* GraphQL */ `
  query LikesByRecipeID(
    $recipeID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByRecipeID(
      recipeID: $recipeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        recipeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      email
      feedback
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        feedback
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
