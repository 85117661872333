import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import Delete from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";
import Cancel from "@mui/icons-material/Cancel";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Checkbox from "@mui/material/Checkbox";
import { generateClient } from "aws-amplify/api";
import { uploadData } from 'aws-amplify/storage';
import { createRecipe } from "../graphql/mutations";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { getCurrentUser } from 'aws-amplify/auth';
import { getRecipeTagsDisplayValue } from "../util/constants";
import ImageUploadComponent from "./ImageUploadComponent";
import { styled } from "@mui/system";
import { getUser, getFamilyById, getUserFamilyByUserId } from "../util/api";
import { ListItemSecondaryAction, OutlinedInput } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';

const ListItemStyled = styled(ListItem)(({ theme }) => ({
    borderBottom: '1px solid #000',
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
    padding: "2px",
    marginLeft: theme.spacing(1),
}));

const DeleteStyled = styled(Delete)(({ theme }) => ({
    color: "red",
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    width: "75%",
}));

const SelectStyled = styled(Select)(({ theme }) => ({
    flexGrow: 1,
    width: 200,
    marginBottom: theme.spacing(2),
}));

const ImageContainerStyled = styled('div')(({ theme }) => ({
    margin: "0 auto",
    maxWidth: "100%",
    "& img": {
        width: "100%",
        borderRadius: "10px",
    },
}));

const StyledInstructionIngredient = styled(TextField)(({ theme }) => ({
    width: "100%",
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    width: "100%",
    wordWrap: "break-word",
    //on hover change color
    "&:hover": {
        color: theme.palette.secondary.main,
    },
}));


const StyledButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: 40,
    marginBottom: theme.spacing(2),
    borderWidth: 2,
    "&:hover": {
        borderWidth: 2,
    },
    textEmphasis: "bold",
    fontWeight: 600,
    fontSize: "1.2rem",
    width: "50%",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
}));

const StyledButtonContainer = styled('div')(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1),
}));



function RecipeCreateForm(props) {
    const client = generateClient();
    const [user, setUser] = useState({});
    const [Ingredients, setIngredients] = useState([]);
    const [newIngredient, setNewIngredient] = useState("");
    const [Instructions, setInstructions] = useState([]);
    const [newInstruction, setNewInstruction] = useState("");
    const [Title, setTitle] = useState("");
    const [About, setAbout] = useState("");
    const [PrepTime, setPrepTime] = useState(0);
    const [CookTime, setCookTime] = useState(0);
    const [Servings, setServings] = useState("");
    const [SelectedRecipeTags, setSelectedRecipeTags] = useState([]);
    const [TitleError, setTitleError] = useState(false);
    const [InstructionsError, setInstructionsError] = useState(false);
    const [IngredientsError, setIngredientsError] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [familyList, setFamilyList] = useState([]);
    const [editingInstructionIndex, setEditingInstructionIndex] = useState(null);
    const [editingInstructionValue, setEditingInstructionValue] = useState("");
    const [editingIngredientsIndex, setEditingIngredientsIndex] = useState(null);
    const [editingIngredientsValue, setEditingIngredientsValue] = useState("");
    const [selectedFamily, setSelectedFamily] = useState(0);
    function addItem(newItem, setNewItem, items, setItems) {
        if (newItem === "") return;
        items.push(newItem);
        setItems([...items]);
        setNewItem("");
    }

    useEffect(() => {
        const fetchData = async () => {
            const authUser = await getCurrentUser();
            const user = await getUser(authUser.userId);
            setUser(user);
            const userFamilyList = await getUserFamilyByUserId(user.id);
            const familyList = await Promise.all(userFamilyList.map(async (family) => {
                const familyData = await getFamilyById(family.familyID);
                return familyData;
            }));
            setFamilyList(familyList);

        }
        fetchData();
    }, []);


    //create validation function
    function validate() {
        var validatitionCorrect = true;
        if (Title === "") {
            setTitleError(true);
            validatitionCorrect = false;
        }
        if (Instructions.length === 0) {
            setInstructionsError(true);
            validatitionCorrect = false;
        }
        if (Ingredients.length === 0) {
            setIngredientsError(true);
            validatitionCorrect = false;
        }

        return validatitionCorrect;
    }


    async function createRecipeOnSubmit() {
        if (!validate()) {
            console.log("Validation failed")
            return;
        };

        const imageKey = `${Title}-${Date.now()}`;
        const recipe = {
            title: Title,
            about: About,
            prepTime: PrepTime,
            cookTime: CookTime,
            servings: Servings,
            ingredients: Ingredients,
            instructions: Instructions,
            userID: user.id + "::" + user.username,
            tags: SelectedRecipeTags,
            likeCount: 0,
            familyID: selectedFamily
        };

        if (imageFile) {
            recipe.image = imageKey;
        }

        try {
            const result = await client.graphql({
                query: createRecipe.replaceAll("__typename", ""),
                variables: {
                    input: {
                        ...recipe,
                    }
                },
            });

            if (!result.data || !result.data.createRecipe) {
                console.error('Failed to create recipe');
                return;
            }

            //once recipe is created, upload image
            if (imageFile) {
                try {
                    const uploadTask = uploadData({
                        key: imageKey,
                        data: imageFile,
                    });
                    const imageResult = await uploadTask.result;
                    console.log("image upload:", imageResult);
                } catch (error) {
                    console.error("Image upload failed:", error);
                }
            }
            //set location to dashboard
            window.location.href = "/recipe?id=" + result.data.createRecipe.id;;
        }
        catch (error) {
            console.log(error);
        }

    }

    const handleDragEndInstruction = (result) => {
        if (!result.destination) return;

        const newInstructions = Array.from(Instructions);
        const [reorderedItem] = newInstructions.splice(result.source.index, 1);
        newInstructions.splice(result.destination.index, 0, reorderedItem);

        setInstructions(newInstructions);
    };

    const handleDragEndIngredient = (result) => {
        if (!result.destination) return;

        const newIngredients = Array.from(Ingredients);
        const [reorderedItem] = newIngredients.splice(result.source.index, 1);
        newIngredients.splice(result.destination.index, 0, reorderedItem);

        setIngredients(newIngredients);
    };


    return (
        <Section bgColor={props.bgColor} size={props.size}>
            <Container>
                <SectionHeader
                    title="Create a Recipe"
                    subtitle={props.subtitle}
                    size={4}
                    textAlign="center"
                />
                <Grid container={true} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            style={{ width: "75%" }}
                            error={TitleError}
                            label="Title"
                            variant="outlined"
                            type="text"
                            name="Title"
                            inputProps={{ maxLength: 40 }}
                            placeholder="Chocolate Chip Cookies"
                            value={Title}
                            helperText={TitleError ? "Title is required" : ""}
                            onChange={(event) => setTitle(event.target.value)} />

                        <br /><br />
                        <InputLabel id="FamilyInput">Add to Family</InputLabel>
                        <RadioGroup value={selectedFamily} onChange={event => setSelectedFamily(event.target.value)}>
                            <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="None"
                            />
                            {familyList.map((family) => {
                                return (
                                    <FormControlLabel
                                        key={family.id}
                                        value={family.id}
                                        control={<Radio />}
                                        label={family.name}
                                    />
                                )
                            })}
                        </RadioGroup>

                        <InputLabel id="RecipeTagsInput">Recipe Tags</InputLabel>
                        <SelectStyled
                            labelId="RecipeTagsInput"
                            multiple
                            label="Recipe Tags"
                            value={SelectedRecipeTags}
                            input={<OutlinedInput label="Recipe Tags" />}
                            renderValue={(selected) => selected.map(value => getRecipeTagsDisplayValue[value]).join(', ')}>
                            {Object.entries(getRecipeTagsDisplayValue).map(([enumValue, displayName]) => (
                                <MenuItem key={enumValue} value={enumValue}>
                                    <ListItemText primary={displayName} />
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            checked={SelectedRecipeTags.includes(enumValue)}
                                            onChange={(event) => {
                                                const checked = event.target.checked;
                                                setSelectedRecipeTags(prevTags =>
                                                    checked
                                                        ? [...prevTags, enumValue]
                                                        : prevTags.filter(item => item !== enumValue)
                                                );
                                            }}
                                        />
                                    </ListItemSecondaryAction>
                                </MenuItem>
                            ))}
                        </SelectStyled>
                        <br />
                        <TextFieldStyled
                            minRows={4}
                            variant="outlined"
                            label="About"
                            type="text"
                            multiline
                            name="about"
                            inputProps={{ maxLength: 400 }}
                            placeholder="This recipe was passed down through my grandmother..."
                            value={About}
                            onChange={(event) => setAbout(event.target.value)} />
                        <FormHelperText>Give a small snippet about how you created or was passed down this recipe!</FormHelperText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ImageUploadComponent setImageFile={setImageFile} setImageUrl={setImageUrl} imageFile={imageFile} />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            label="Prep (minutes)"
                            variant="outlined"
                            type="number"
                            name="PrepTime"
                            value={PrepTime}
                            onChange={(event) => (setPrepTime(event.target.value))} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Cook (minutes)"
                            variant="outlined"
                            type="number"
                            name="CookTime"
                            value={CookTime}
                            onChange={(event) => (setCookTime(event.target.value))} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Servings"
                            variant="outlined"
                            type="text"
                            name="Servings"
                            value={Servings}
                            onChange={(event) => (setServings(event.target.value))} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DragDropContext onDragEnd={handleDragEndIngredient}>
                            <Droppable droppableId="ingredients">
                                {(provided) => (
                                    <List {...provided.droppableProps} ref={provided.innerRef}>
                                        {Ingredients.map((ingredient, index) => (
                                            <Draggable key={ingredient} draggableId={ingredient} index={index}>
                                                {(provided) => (
                                                    <ListItemStyled ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        {editingIngredientsIndex === index ? (
                                                            <StyledInstructionIngredient
                                                                value={editingIngredientsValue}
                                                                onChange={(e) => setEditingIngredientsValue(e.target.value)}
                                                                onBlur={() => {
                                                                    const newIngredients = [...Ingredients];
                                                                    newIngredients[index] = editingIngredientsValue;
                                                                    setIngredients(newIngredients);
                                                                    setEditingIngredientsIndex(null);
                                                                }}
                                                            />
                                                        ) : (
                                                            <StyledListItemText onClick={() => {
                                                                setEditingIngredientsIndex(index);
                                                                setEditingIngredientsValue(ingredient);
                                                            }}>
                                                                {ingredient}
                                                            </StyledListItemText>
                                                        )}
                                                        <IconButtonStyled onClick={() => setIngredients(Ingredients.filter((item) => item !== ingredient))}>
                                                            <DeleteStyled />
                                                        </IconButtonStyled>
                                                    </ListItemStyled>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </List>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <StyledInstructionIngredient
                            label="Ingredients"
                            variant="outlined"
                            type="text"
                            name="Ingredients"
                            onChange={(event) => setNewIngredient(event.target.value)} value={newIngredient} placeholder="1 cup of flour..."
                            error={IngredientsError}
                            helperText={IngredientsError ? "Your Ingredients cannot be empty!" : ""} />
                        <IconButton onClick={() => addItem(newIngredient, setNewIngredient, Ingredients, setIngredients)}><Add /></IconButton>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <DragDropContext onDragEnd={handleDragEndInstruction}>
                            <Droppable droppableId="instructions">
                                {(provided) => (
                                    <List {...provided.droppableProps} ref={provided.innerRef}>
                                        {Instructions.map((instruction, index) => (
                                            <Draggable key={instruction} draggableId={instruction} index={index}>
                                                {(provided) => (
                                                    <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        {editingInstructionIndex === index ? (
                                                            <StyledInstructionIngredient
                                                                value={editingInstructionValue}
                                                                onChange={(e) => setEditingInstructionValue(e.target.value)}
                                                                onBlur={() => {
                                                                    const newInstructions = [...Instructions];
                                                                    newInstructions[index] = editingInstructionValue;
                                                                    setInstructions(newInstructions);
                                                                    setEditingInstructionIndex(null);
                                                                }}
                                                            />
                                                        ) : (
                                                            <StyledListItemText onClick={() => {
                                                                setEditingInstructionIndex(index);
                                                                setEditingInstructionValue(instruction);
                                                            }}>
                                                                {Instructions.indexOf(instruction) + 1 + ". " + instruction}
                                                            </StyledListItemText>
                                                        )}
                                                        <IconButtonStyled onClick={() => setInstructions(Instructions.filter((item) => item !== instruction))}>
                                                            <DeleteIcon />
                                                        </IconButtonStyled>
                                                    </ListItem>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </List>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <StyledInstructionIngredient
                            label="Instructions"
                            variant="outlined"
                            type="text"
                            name="Instructions"
                            onChange={(event) => setNewInstruction(event.target.value)} value={newInstruction} placeholder="Set oven to 375."
                            error={InstructionsError}
                            helperText={InstructionsError ? "Your Instructions cannot be empty!" : ""}
                        />
                        <IconButton onClick={() => addItem(newInstruction, setNewInstruction, Instructions, setInstructions)}><Add /></IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledButtonContainer>
                            <StyledButton color="secondary" variant="contained" onClick={() => createRecipeOnSubmit()}>Create Recipe</StyledButton>
                        </StyledButtonContainer>
                    </Grid>
                </Grid>
            </Container>
        </Section>
    );
}

export default withAuthenticator(RecipeCreateForm);
