import React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Link } from  "react-router-dom";
import logo from "../../images/Recipe Roots Logo Symbol.png";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: 'auto',
  maxWidth: 400,
  height: 225,
  textAlign: 'center', 
  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${logo})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));


function InviteMembers(props) {
  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h3" gutterBottom align="center">
            Start Cooking
          </Typography>
        </Grid>
        <Grid item xs={12} style={{paddingTop:'4px'}}>
          <Typography variant="body1" gutterBottom>
          Ready to embark on your culinary adventure? Start discovering family recipes by searching above. Join Recipe Roots' passionate community of food enthusiasts celebrating family, culture, and cooking. 
          </Typography>
        </Grid>
        <Grid item xs={12} align="center" style={{paddingTop:'4px'}}>
          <Button component={Link} to="/createRecipe" variant="contained" color="primary">
            Create a Recipe
          </Button>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default InviteMembers;