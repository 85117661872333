import React from "react";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const themeConfig = {
  light: {
    palette: {
      mode: "light",
      primary: {
        main: "#cca06e",
        // dark: "#493146"
      },
      secondary: {
        main: "#eb5f52",
        light: "#feefd8",
      },
      background: {
        default: "#fff",
        paper: "#fff",
      },
    },
  },

  common: {
    typography: {
      fontSize: 14,
      fontFamily: '"Georgia", "Lucida Sans Unicode", "Helvetica",  "sans-serif"',
      button: { textTransform: "none"},
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1920,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "#root": {
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            "& > *": {
              flexShrink: 0,
            },
          },
        },
      },
    },
  },
};

function getTheme(name) {
  return createTheme({
    ...themeConfig[name],
    ...themeConfig.common,
    components: {
      ...(themeConfig[name] && themeConfig[name].components),
      ...(themeConfig.common && themeConfig.common.components),
    },
  });
}

export const ThemeProvider = (props) => {
  const theme = getTheme("light");

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </MuiThemeProvider>
  );
};

export function useDarkMode() {
  return { value: false, toggle: () => {} };
}