import React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import logo from "../../images/Recipe Roots Logo Symbol.png";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: 'auto',
  maxWidth: 400,
  height: 225,
  textAlign: 'center', 
  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${logo})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
  }));

function Intro(props) {
  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h3" gutterBottom align="center">
            Welcome to RecipeRoots!
          </Typography>
        </Grid>
        <Grid item xs={12} style={{paddingTop:"4px"}}>
          <Typography variant="body1" gutterBottom>
          Welcome to Recipe Roots. Explore a platform where culinary traditions merge with digital innovation, offering a diverse array of recipes from families worldwide. Join Recipe Roots community to delve into cultures and cuisines from around the globe, savoring traditions, one family recipe at a time.
          </Typography>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default Intro;