import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { Alert, AlertTitle, CircularProgress } from '@mui/material';
import { Grid, TextField, Typography, FormHelperText, Button } from "@mui/material";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { generateClient } from "aws-amplify/api";
import { updateUser, updateRecipe, deleteUserFamily } from "../graphql/mutations";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { styled } from "@mui/system";
import { getRecipesByUser, getFamilyById, getUserFamilyByUserId, getUserFamilyByFamilyID, getUser } from "../util/api";
import { Link } from "react-router-dom";
import { getCurrentUser } from "aws-amplify/auth";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import ImageAvatarUploadComponent from "./ImageAvatarUploadComponent";
import { uploadData, remove } from 'aws-amplify/storage';

const GridItem = styled(Grid)(({ theme }) => ({
    textAlign: "left"
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    width: "45%",
    padding: theme.spacing(1),
    borderRadius: 40,
}));

const TextArea = styled(TextField)({
    width: "100%",
});

const TextInput = styled(TextField)({
    width: "40%",
    minWidth: "225px",
});

const StyledSection = styled(Section)(({ theme }) => ({
    marginTop: "20px",
}));

const AvatarWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(12),
    height: theme.spacing(12),
}));

function UserEditForm({ user }) {
    const client = generateClient();
    const [username, setUsername] = useState("");
    const [about, setAbout] = useState("");
    const [userId, setUserId] = useState(0);
    const [saved, setSaved] = useState(false);
    const [familyList, setFamilyList] = useState([]);
    const [alert, setAlert] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageKeyCurrent, setImageKeyCurrent] = useState(null);
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {



        const fetchFamilyData = async () => {

            const authUser = await getCurrentUser();
            if (!authUser) {
                return;
            }
            const user = await getUser(authUser.userId);
            console.log(user);
            setUsername(user.username);
            setAbout(user.about);
            setUserId(user.id);
            setImageKeyCurrent(user.image);
            setImageUrl(user.imageUrl);
            setName(user.name);

            const families = await getUserFamilyByUserId(user.id);
            if (families.length > 0) {
                let tempFamilyList = [];
                for (let i = 0; i < families.length; i++) {
                    const family = await getFamilyById(families[i].familyID);
                    tempFamilyList.push(family);
                }
                setFamilyList(tempFamilyList);
            }
        }
        fetchFamilyData();
    }, [user]);


    async function updateUserFunction() {
        setLoading(true);
        var imageKey = null;
        if (imageFile) {
            imageKey = `${userId}-${Date.now()}`;
        }
        const user = {
            id: userId,
            about: about,
            name: name,
        };
        if (imageFile) {
            user.image = imageKey;
        }
        console.log(user);
        try {
            await client.graphql({ query: updateUser, variables: { input: user } });
            if (imageFile) {
                //upload the image
                const uploadTask = uploadData({
                    key: imageKey,
                    data: imageFile,
                });
                const imageResult = await uploadTask.result;
                console.log(imageResult);
                if (imageKeyCurrent) {
                    //delete the old image
                    const deleteTask = remove(imageKeyCurrent);
                    const deleteResult = await deleteTask.result;
                    console.log(deleteResult);
                }
            }
            setSaved(true);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function leaveFamily(family) {
        if (window.confirm("Leaving a family will remove all your recipes from that family. Are you sure you want to leave?")) {
            //first check to see if this person is the owner of the family
            if (family.owner === username) {
                //if they are the owner, we will not allow them to leave the family
                setAlert("You are the owner of this family. You must transfer ownership first.");
                setAlertOpen(true);
                return;
            }
            try {
                const user = await getCurrentUser();
                const userFamilies = await getUserFamilyByFamilyID(family.id);
                const userFamily = userFamilies.find((f) => f.userID === user.userId);
                const model = {
                    id: userFamily.id
                };
                const response = await client.graphql({ query: deleteUserFamily, variables: { input: model } });
                if (response.data.deleteUserFamily) {
                    console.log("User family deleted");
                }
                else {
                    console.log("User family not deleted");
                }

                //update the user family list
                const newFamilyList = familyList.filter((f) => f.id !== family.id);
                setFamilyList(newFamilyList);


                //after updating user, we need to update the recipe familyID to 0
                //get all recipes by user
                const allRecipes = await getRecipesByUser(userId + "::" + username);
                const updateRecipes = [];
                //iterate through recipes, finding the ones that belong to the family.id
                for (var i = 0; i < allRecipes.length; i++) {
                    if (allRecipes[i].familyID === family.id) {
                        updateRecipes.push(allRecipes[i]);
                    }
                }


                //update each recipe
                updateRecipes.forEach(async (recipe) => {
                    const updatedRecipe = {
                        id: recipe.id,
                        familyID: 0
                    };
                    await client.graphql({ query: updateRecipe, variables: { input: updatedRecipe } });
                });
                setSaved(true);

            }
            catch (error) {
                console.log(error);
            }
        }
    }

    if(loading){
        return <CircularProgress/>
    }

    return (
        <StyledSection size="appBar">
            <Container>

                {alertOpen && <Alert severity="error" onClose={() => setAlertOpen(false)}>
                    <AlertTitle>Error</AlertTitle>
                    {alert}
                </Alert>}
                {saved && <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    User Updated — <strong>{username}</strong>
                </Alert>}
                <SectionHeader
                    title={"Update - " + username}
                    size={4}
                    textAlign="center"
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                        <AvatarWrapper>
                            <ImageAvatarUploadComponent setImageFile={setImageFile} setImageUrl={setImageUrl} imageFile={imageFile} imageUrl={imageUrl} />
                        </AvatarWrapper>
                    </Grid>
                    <Grid item xs={12} sm={9} spacing={3}>
                        <Box mt={2}>
                            <Typography variant="h5">{username}</Typography>
                        </Box>
                        <Box mt={2}>
                            <TextInput
                                variant="outlined"
                                label="Name"
                                type="text"
                                name="name"
                                inputProps={{ maxLength: 20 }}
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </Box>
                        <Box mt={2}>
                            <TextArea style={{ marginTop: '8px', marginBottom: '8px' }}
                                inputProps={{ maxLength: 500 }}
                                minRows={4}
                                variant="outlined"
                                label="About"
                                type="text"
                                multiline
                                name="about"
                                value={about}
                                onChange={(event) => setAbout(event.target.value)}
                            />
                            <FormHelperText>Give a small snippet about your cooking history!</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <SubmitButton variant="contained" color="secondary" onClick={() => updateUserFunction()}>Save</SubmitButton>
                    </Grid>
                </Grid>

                <Grid container={true} spacing={3} style={{ marginTop: '8px' }}>
                    {familyList.length > 0 &&
                        <GridItem item xs={12}>
                            <Typography variant="h5">Family Info</Typography>
                            <Typography variant="body1" component="p">
                                You are a member of the following families:
                            </Typography>
                            {familyList.map((family, index) => (
                                <Typography variant="body1" component="p" key={index}>
                                    <Link to={`/family?id=${family.id}`}>
                                        {family.name}
                                    </Link>

                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        onClick={() => leaveFamily(family)}
                                        style={{ marginLeft: "10px" }}
                                    >
                                        Leave
                                    </Button>

                                </Typography>
                            ))}
                        </GridItem>
                    }

                </Grid>
            </Container>
        </StyledSection>
    );
}

export default withAuthenticator(UserEditForm);