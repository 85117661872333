import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { Alert, AlertTitle, Box } from '@mui/material';
import { Grid, MenuItem, TextField, Select, Typography, InputLabel, Chip, FilledInput, FormHelperText, Button, IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Checkbox from "@mui/material/Checkbox";
import {getUserFamilyByFamilyID} from "../util/api";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { generateClient } from "aws-amplify/api";
import { updateFamily, deleteFamily, deleteUserFamily } from "../graphql/mutations";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { getCurrentUser } from 'aws-amplify/auth';
import { getFamilyOriginsDisplayValues } from "../util/constants";
import { styled } from "@mui/system";

const StyledGridItem = styled(Grid)(({ theme }) => ({
    textAlign: "center",
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    width: "45%",
    padding: theme.spacing(1),
    borderRadius: 40,
}));

const TextArea = styled(TextField)({
    width: "75%",
});

const TextInput = styled(TextField)({
    width: "40%",
    minWidth: "225px",
});

const MultipleSelects = styled(Select)({
    width: "50%",
});

function FamilyEditForm(props) {
    const client = generateClient();
    const [user, setUser] = useState({});
    const [FamilyOrigins, setFamilyOrigins] = useState([]);
    const [FamilyName, setFamilyName] = useState("");
    const [About, setAbout] = useState("");
    const [saved, setSaved] = useState(false);
    const [FamilyNameError, setFamilyNameError] = useState(false);

    const origins = Object.entries(getFamilyOriginsDisplayValues);
    //convert the origins to alphabetized list
    origins.sort((a, b) => a[1].localeCompare(b[1]));

    //get currentUser
    const fetchUser = async () => {
        try {
            const authUser = await getCurrentUser();
            setUser(authUser);
            return authUser;
        }
        catch (error) {
            console.log("Error fetching user: ", error);
            return null;
        }
    }


    useEffect(() => {
        fetchUser();
        setFamilyOrigins(props.family.origins);
        setFamilyName(props.family.name);
        setAbout(props.family.about);
    }, []);

    //create validation function
    function validate() {
        var validatitionCorrect = true;
        if (FamilyName === "") {
            setFamilyNameError(true);
            validatitionCorrect = false;
        }
        return validatitionCorrect;
    }


    async function updateRecipe() {
        if (!validate()) {
            return;
        };
        //convert the origins to enums
        const family = {
            id: props.family.id,
            name: FamilyName,
            about: About,
            origins: FamilyOrigins
        };
        try {
            //update family\
            await client.graphql({
                query: updateFamily.replaceAll("__typename", ""),
                variables: {
                    input: {
                        ...family,
                    }
                }
            }).then((result) => {
                setSaved(true);
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    async function deleteFamilyMethod() {
        if (!window.confirm("Are you sure you want to delete this family?")) {
            return;
        }
        try {
            //delete family
            await client.graphql({
                query: deleteFamily.replaceAll("__typename", ""),
                variables: {
                    input: {
                        id: props.family.id,
                    }
                }
            }).then((result) => {
                setSaved(true);
            });

            //delete all the users relationship to the family
            await getUserFamilyByFamilyID(props.family.id).then((result) => {
                if (result.length > 0) {
                    result.forEach(async (userFamily) => {
                        await client.graphql({
                            query: deleteUserFamily.replaceAll("__typename", ""),
                            variables: {
                                input: {
                                    id: userFamily.id,
                                }
                            }
                        }).then((result) => {
                            setSaved(true);
                        }).catch((error) => {
                            console.log(error);

                        });
                    });
                }
            });
            window.location.href = "/dashboard/1";
            
        }
        catch (error) {
            console.log(error);
        }

    }


    return (
        <Section size={props.size}>
            <Container>
                {saved && <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    Family Updated — <strong>{FamilyName}</strong>
                </Alert>}
                <SectionHeader
                    title={"Update - " + FamilyName}
                    subtitle="Update your family info here! Add members down below!"
                    size={4}
                    textAlign="center"
                />
                <Grid container={true} spacing={3}>
                    <Grid item xs={12}>
                        <TextInput
                            error={FamilyNameError}
                            label="Family Name"
                            inputProps={{ maxLength: 30 }}
                            variant="outlined"
                            type="text"
                            name="FamilyName"
                            placeholder="Papa Bobby's Family"
                            value={FamilyName}
                            helperText={FamilyNameError ? "Family Name is required" : ""}
                            onChange={(event) => setFamilyName(event.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextArea
                            inputProps={{ maxLength: 500 }}
                            minRows={4}
                            variant="outlined"
                            label="About"
                            type="text"
                            multiline
                            name="about"
                            placeholder="We are a 3rd generation Italian family originally from Sicily. We love to cook and eat together!"
                            value={About}
                            onChange={(event) => setAbout(event.target.value)} />
                        <FormHelperText>Give a small snippet about your family!</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel id="FamilyOriginsInput">Family Origins</InputLabel>
                        <MultipleSelects
                            label="Family Origins"
                            multiple
                            value={FamilyOrigins}
                            renderValue={(selected) => selected.map(value => origins.find(([enumValue]) => enumValue === value)[1]).join(', ')}
                        >
                            {origins.map(([enumValue, displayName]) => (
                                <MenuItem key={enumValue} value={enumValue}>
                                    <ListItemText primary={displayName} />
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            checked={FamilyOrigins.includes(enumValue)}
                                            onChange={(event) => {
                                                const checked = event.target.checked;
                                                setFamilyOrigins(prevOrigins =>
                                                    checked
                                                        ? [...prevOrigins, enumValue]
                                                        : prevOrigins.filter(item => item !== enumValue)
                                                );
                                            }}
                                        />
                                    </ListItemSecondaryAction>
                                </MenuItem>
                            ))}
                        </MultipleSelects>
                    </Grid>

                    <StyledGridItem item xs={12}>
                        <SubmitButton variant="contained" color="secondary" onClick={() => updateRecipe()}>Save Family</SubmitButton>
                    </StyledGridItem>
                    <StyledGridItem item xs={12}>
                        <Box display={"flex"} justifyContent={"right"}>
                            <Button variant="outlined" color="secondary" onClick={() => deleteFamilyMethod()}>Delete Family</Button>
                        </Box>
                    </StyledGridItem>
                </Grid>
            </Container>
        </Section>
    );
}

export default withAuthenticator(FamilyEditForm);
