import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import ListItemButton from "@mui/material/ListItemButton";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Section from "./Section";
import { Link } from "react-router-dom";
import { useDarkMode } from "./../util/theme";
import { signOut, getCurrentUser } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { styled } from "@mui/system";
import { useLocation } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import LogoutIcon from '@mui/icons-material/Logout';
import EmailIcon from '@mui/icons-material/Email';
import FeedbackIcon from '@mui/icons-material/Feedback';
import PeopleIcon from '@mui/icons-material/People';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import LoginIcon from '@mui/icons-material/Login';
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from 'react-router-dom';
import { Avatar, Dialog } from "@mui/material";
import { getUser } from "../util/api";
import FeedbackDialog from "./FeedbackDialog";

const LogoStyled = styled('img')(({ theme }) => ({
  height: 45,
  marginRight: theme.spacing(1),
}));

const LogoSymbolStyled = styled('img')(({ theme }) => ({
  height: 25,

  marginRight: theme.spacing(1),
}));

const DrawerListStyeled = styled(List)(({ theme }) => ({
  width: 250,
}));

const SpacerStyled = styled('div')(({ theme }) => ({
  flexGrow: 1,
}));

const SearchFieldStyled = styled(TextField)(({ theme }) => ({
  maxWidth: '450px',
  width: '100%',
  flexShrink: 0,
  display: 'flex'
}));

const StyledSignInButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  borderRadius: 20,
}));

function Navbar(props) {
  const [authenticated, setAuthenticated] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);

  const location = useLocation();

  useEffect(() => { isLoggedIn() }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //on exit of the recipe screen, set the searchQuery to null
  useEffect(() => {
    if (location.pathname !== "/generalSearch") {
      setSearchQuery(''); // Reset searchQuery
    }
  }, [location]);

  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signedIn':
        console.log('user signed in');
        isLoggedIn();
        setAuthenticated(true);
        if (localStorage.getItem('firstSignIn') === null) {
          console.log('This is the first time the user has signed in');
          // Set the flag in the local storage to indicate that the user has signed in before
          localStorage.setItem('firstSignIn', 'true');
        }
        break;
      case 'signedOut':
        console.log('user signed out');
        setAuthenticated(false);
        window.location.href = "/";
        break;
      default:
        break;
    }
  });


  async function handleSignOut() {
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  //This checks on load, before any login events have happened.
  async function isLoggedIn() {
    try {
      await getCurrentUser().then(
        async (user) => {
          if (user) {
            const resp = await getUser(user.userId);
            setUser(resp);
            setAuthenticated(true);
          } else {
            setAuthenticated(false);
          }
        }
      );
    } catch (error) {
      console.log('error checking if user is signed in: ', error);
      setAuthenticated(false);
    }
  }

  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);
  const navigate = useNavigate();

  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };
  return (
    <Section bgColor={"white"} size="auto">
      <AppBar position="fixed" color="transparent" elevation={2} style={{ zIndex: 100, backgroundColor: 'white' }} >
        <Container disableGutters={true}>
          <Toolbar>
            <Link to="/">
              <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <LogoStyled src={logo} alt="Logo" />
              </Box>
              <Box sx={{ display: { sm: 'block', md: 'none' } }}>
                <LogoSymbolStyled src={props.logoSymbol} alt="Logo" />
              </Box>
            </Link>
            <SpacerStyled />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setSearchQuery(searchQuery);
                // Navigate to the search page
                navigate("/generalSearch?searchQuery=" + searchQuery);
              }}
              style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            >
              <SearchFieldStyled
                id="search-bar"
                className="text"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                label="Search"
                variant="outlined"
                placeholder="Recipe, Family, User..."
                size="small"
                value={searchQuery}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" aria-label="search" component={Link} to={"/generalSearch?searchQuery=" + searchQuery}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            <SpacerStyled />

            <Box sx={{ display: { sm: 'block', md: 'none' } }}>
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              {!authenticated && (
                <>
                  <StyledSignInButton component={Link} to="/dashboard/0" variant="contained">
                    <Box display="flex" alignItems="center" gap={1}>
                      Sign in
                    </Box>
                  </StyledSignInButton>
                </>
              )}

              {authenticated && (
                <>
                  <Button
                    color="inherit"
                    aria-label="Account"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleOpenMenu(event, "account-menu");
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <Avatar alt={user ? user.username : "Account"} src={user ? user.imageUrl : ""} />
                      {user ? user.username : "Account"}
                      <Divider orientation="vertical" flexItem />
                      <ExpandMoreIcon />
                    </Box>
                  </Button>
                  <Menu
                    id="account-menu"
                    open={
                      menuState && menuState.id === "account-menu"
                        ? true
                        : false
                    }
                    anchorEl={menuState && menuState.anchor}
                    getContentAnchorEl={undefined}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem component={Link} to="/dashboard/0">
                      <DashboardCustomizeIcon style={{ marginRight: '8px' }} /> Dashboard
                    </MenuItem>
                    <Divider />
                    <MenuItem component={Link} to="/createRecipe">
                      <DinnerDiningIcon style={{ marginRight: '8px' }} /> Create a Recipe
                    </MenuItem>
                    <MenuItem component={Link} to="/familyCreate">
                      <PeopleIcon style={{ marginRight: '8px' }} /> Create a Family
                    </MenuItem>
                    <Divider />                    
                    <MenuItem component={Link} to="/dashboard/0">
                      <DinnerDiningIcon style={{ marginRight: '8px' }} /> Recipes
                    </MenuItem>
                    <MenuItem component={Link} to="/dashboard/1">
                      <PeopleIcon style={{ marginRight: '8px' }} /> Families
                    </MenuItem>
                    <MenuItem component={Link} to="/dashboard/2">
                      <FavoriteIcon style={{ marginRight: '8px' }} /> Likes
                    </MenuItem>
                    <MenuItem component={Link} to="/familyInvites">
                      <EmailIcon style={{ marginRight: '8px' }} /> Invites
                    </MenuItem> 
                    <Divider />
                    <MenuItem onClick={handleClickOpen}>
                      <FeedbackIcon style={{ marginRight: '8px' }} /> Feedback
                    </MenuItem>
                    <MenuItem component={Link} to="/userEdit">
                      <SettingsIcon style={{ marginRight: '8px' }} /> User Settings
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleSignOut(event);
                      }}
                    >
                      <LogoutIcon style={{ marginRight: '8px' }} /> Sign out
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <DrawerListStyeled
          onClick={() => setDrawerOpen(false)}
        >
          {!authenticated && (
            <>
              <ListItemButton component={Link} to="/dashboard/0">
                <LoginIcon style={{ marginRight: '8px' }} /><ListItemText> Sign in</ListItemText>
              </ListItemButton>
              <Divider />
              <ListItemButton component={Link} to="/createRecipe">
                <DinnerDiningIcon style={{ marginRight: '8px' }} /><ListItemText> Create a Recipe</ListItemText>
              </ListItemButton>
            </>
          )}

          {authenticated && (
            <>
              <ListItemButton component={Link} to="/dashboard/0" >
                <DashboardCustomizeIcon style={{ marginRight: '8px' }} /><ListItemText> Dashboard</ListItemText>
              </ListItemButton>
              <Divider />
              <ListItemButton component={Link} to="/createRecipe" >
                <DinnerDiningIcon style={{ marginRight: '8px' }} /><ListItemText>  Create a Recipe</ListItemText>
              </ListItemButton>
              <ListItemButton component={Link} to="/familyCreate" >
                <PeopleIcon style={{ marginRight: '8px' }} /><ListItemText> Create a Family</ListItemText>
              </ListItemButton>
              <Divider />
              <ListItemButton component={Link} to="/dashboard/0" >
                <DinnerDiningIcon style={{ marginRight: '8px' }} /><ListItemText> Recipes </ListItemText>
              </ListItemButton>
              <ListItemButton component={Link} to="/dashboard/1" >
                <PeopleIcon style={{ marginRight: '8px' }} /><ListItemText> Families </ListItemText>
              </ListItemButton>
              <ListItemButton component={Link} to="/dashboard/2" >
                <FavoriteIcon style={{ marginRight: '8px' }} /><ListItemText>  Likes </ListItemText>
              </ListItemButton>
              <ListItemButton component={Link} to="/familyInvites" >
                <EmailIcon style={{ marginRight: '8px' }} /><ListItemText> Invites </ListItemText>
              </ListItemButton>
              
              <Divider />
              <ListItemButton onClick={handleClickOpen}>
                <FeedbackIcon style={{ marginRight: '8px' }} /><ListItemText> Feedback </ListItemText>
              </ListItemButton>
              <ListItemButton component={Link} to="/userEdit" >
                <SettingsIcon style={{ marginRight: '8px' }} /><ListItemText> User Settings </ListItemText>
              </ListItemButton>
              
              
              <ListItemButton
                onClick={(event) => {
                  handleSignOut(event);
                }}
              >
                <LogoutIcon style={{ marginRight: '8px' }} /> <ListItemText>  Sign out</ListItemText>
              </ListItemButton>
            </>
          )}
        </DrawerListStyeled>
      </Drawer>
      <FeedbackDialog open={open} onClose={handleClose} user={user} />
    </Section>
  );
}

export default Navbar;
