import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/system';
import { getFamilyRecipes } from "../util/api"
import CardActions from '@mui/material/CardActions';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';

const CardStyled = styled(Card)(({ theme, height }) => ({
  width: 325,
  marginBottom: theme.spacing(2),
  //round out edges
  borderRadius: 10,
  padding: 5,
  paddintTop: 0,
  height: height,
}));


const CardActionAreaStyled = styled(CardActionArea)({
  padding: 0,
  margin: 0,
  height: 190, 
});

const Title = styled(Typography)({
  // whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const TypographyStyled = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3, // Change this number to the number of lines you want to display
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});



export default function FamilyCard(props) {

  const [familyRecipies, setFamilyRecipies] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [height, setHeight] = useState(200);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFamilyRecipes(props.family.id);
        setFamilyRecipies(response);
        
      } catch (error) {
        console.log('error getting current user: ', error);
      }
      if(!props.user)
      {
        setIsOwner(false);
        return;
      }
      if(props.family.owner === props.user.username){
        setIsOwner(true);
        setHeight(260);
      }

    };
    fetchData();
  }, []);


  



  return (
    <CardStyled height={height}>
      <CardActionAreaStyled>
        <CardContent onClick={() => window.location.href = "/family?id=" + props.family.id} style={{paddingTop:'0px'}}>
          <Title gutterBottom variant="h6" component="h2" >
            {props.family.name}
          </Title>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            {props.family.origins.slice(0, 1).map((origins, index) => (
              <Chip key={index} label={origins} size="small" style={{ margin: 2 }} />
            ))}
            {props.family.origins.length > 1 &&
              <Typography variant="body2" color="textSecondary" component="p">
                + {props.family.origins.length - 1} more
              </Typography>
            }
          </div>
          <Typography variant="body2" color="textSecondary" component="p">
            {familyRecipies?.length} Recipes
          </Typography>
          <TypographyStyled variant="body2" color="textPrimary" component="p">
            {props.family.about}
          </TypographyStyled>
        </CardContent>
      </CardActionAreaStyled>
      {isOwner && 
      <CardActions>
        <IconButton size="small" onClick={() => window.location.href = "/familyEdit?id=" + props.family.id}>
          <Edit />
        </IconButton>
        <Button size="small" color="secondary" variant="contained" onClick={() => window.location.href = "/familyAddMembers?id=" + props.family?.id}>
          Invite Members
        </Button>
      </CardActions>}
    </CardStyled>
  );
}