import React from "react";
import Meta from "./../components/Meta";
import { useLocation } from "react-router-dom";
import ErrorSection from "../components/ErrorSection";
import Section from "../components/Section";
function NotFoundPage(props) {
  const location = useLocation(); // get the location object
  return (
    <>
      <Meta title="404" />
      <Section size="appBar">
      <ErrorSection
        bg="white"
        textColor="dark"
        size="auto"
        title="404"
        subtitle={`The page ${location.pathname} does not exist.`}
      />
      </Section>
    </>
  );
}

export default NotFoundPage;
