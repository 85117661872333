import React from "react";
import Meta from "../components/Meta";
import RecipeCreateForm from "../components/RecipeCreateForm";
import { withAuthenticator } from "@aws-amplify/ui-react";
function recipeCreatePage(props) {

  return (
    <>
    <Meta title="Recipe Roots - Create"/>
      <RecipeCreateForm size="appBar"/>
    </>
    );
}

export default withAuthenticator(recipeCreatePage);
