import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import { styled } from '@mui/system';
import { CardActionArea } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
    width: 275,
    margin: 10,
}));

const AvatarWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(10),
    height: theme.spacing(10),
}));



const UserCard = ({ user }) => {
    return (
        <StyledCard>
            <CardActionArea component={Link} to={`/user?id=${user.id}`}>
                <CardContent>
                    <AvatarWrapper>
                        <StyledAvatar alt={user.username} src={user.imageUrl} />
                    </AvatarWrapper>
                    <Typography variant="h5" component="div">
                        {user.username}
                    </Typography>
                    {user.name !== null &&
                        <Typography variant="body2" color="text.secondary">
                            {user.name}
                        </Typography>
                    }
                    
                    <Typography variant="body2" color="text.secondary">
                        {user.about}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </StyledCard>
    );
};

export default UserCard;