import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography } from '@mui/material';
import { generateClient } from 'aws-amplify/api';
import { createFeedback } from '../graphql/mutations';
import Snackbar from '@mui/material/Snackbar';

const FeedbackDialog = ({ open, onClose, user }) => {
    const [feedback, setFeedback] = useState('');
    const [snackBar, setSnackBar] = useState(false);
    const handleFeedback = async () => {
        try {
            if (feedback === '') {
                throw new Error('Feedback cannot be empty');
            }
            const client = generateClient();
            const feedbackModel =
            {
                email: user.email,
                feedback: feedback
            };

            const result = await client.graphql({ query: createFeedback, variables: { input: { ...feedbackModel } } });
            if (result.data.createFeedback) {
                setSnackBar(true);
                console.log(result);
            }
            //set timeout for 2 seconds
            setTimeout(() => {
                setSnackBar(false);
                onClose();
                setFeedback('');
            }, 2000);
        }
        catch (error) {
            console.error('Error creating feedback', error);
        }

    };

    return (
        <Dialog open={open} onClose={onClose} >
            <DialogTitle>Feedback</DialogTitle>
            <DialogContent>
                <Typography style={{width:'90%'}}variant="body1">Please share any issues or improvements we can make!</Typography>
                <TextField 
                style={{ marginTop: '8px', width:'100%' }} 
                label="Feedback" 
                multiline 
                inputProps={{ maxLength: 500 }}
                rows={4}
                value={feedback} onChange={(e) => setFeedback(e.target.value)} />
                <Typography variant="subtitle2" style={{ marginTop: '8px' }}>Email: {user?.email}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="secondary" onClick={handleFeedback}>Submit</Button>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={snackBar}
                    autoHideDuration={6000}
                    message="Thank you for your feedback!"
                />
            </DialogActions>
        </Dialog>
    );
};

export default FeedbackDialog;