import React, { useEffect, useState } from "react";
import Meta from "../components/Meta";
import RecipeList from "../components/RecipeList";
import FamilyViewSection from "../components/FamilyViewSection";
import { getFamilyById, getFamilyRecipes, getFamilyMembers } from "../util/api";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getCurrentUser } from "aws-amplify/auth";
import ErrorSection from "../components/ErrorSection";
function FamilyViewPage(props) {
    const [family, setFamily] = useState([]);
    const [recipes, setRecipes] = useState([]);
    const [members, setMembers] = useState([]);
    const [isEditable, setIsEditable] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ error, setError ] = useState(false);
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const id = query.get('id');

    useEffect(() => {

        const fetchData = async () => {
            const family = await getFamilyById(id);
            if(family === null) {
                setLoading(false);
                setError(true);
                return;
            }
            setFamily(family);
            const members = await getFamilyMembers(id);
            setMembers(members);
            const familyRecipes = await getFamilyRecipes(id);
            setRecipes(familyRecipes);
            try {
                const user = await getCurrentUser();
                if (user.username === family.owner) {
                    setIsEditable(true);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setIsEditable(false);
                setLoading(false);
            }
            setLoading(false);
        }
        fetchData();
    }, []);

    if(error)   {
        return <ErrorSection size="appBar" title="Error fetching family" subtitle="Please try again later" />
    }

    if (loading) {
        return <CircularProgress />
    }

    return (
        <>
            <Meta title={"Recipe Roots - " + family.name} />
            <FamilyViewSection
                family={family}
                bgColor="default"
                members={members}
                editable={isEditable}
                size="appBar"
            />
            {recipes?.length === 0 && <ErrorSection title="No recipes found for this family" subtitle="Add by editing or creating your recipes, and selecting the 'Add to this family' option" /> }
            <RecipeList
                recipes={recipes}
                size="auto"
            />
        </>
    );
}

export default FamilyViewPage;


